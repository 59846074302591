import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import axios from "axios";
import Spinner from '../../components/Loader';
import { Link } from 'react-router-dom';

class EditLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      First_Name: '',
      Last_Name: '',
      Email: '',
      Mobile: '',
      State: '',
      Company: '',
      Fax: '',
      Lead_Source: '',
      Phone: '',
      Industry: '',
      Annual_Revenue: '',
      Lead_Status: '',
      Rating: '',
      Twitter: '',
      No_of_Employees: '',
      Title: '',
      Secondary_Email: '',
      Website: '',
      Skype_ID: '',
      Street: '',
      Zip_Code: '',
      City: '',
      Country: '',
      Description: '',
      isButtonDisabled: false,
      loaded: false,
      message: '',
      flag: false,
      LeadStatusDD: global.common.LeadStatusDD,
      leadSourceDD: global.common.LeadSourceDD,
      RatingDD: global.common.RatingDD,
      IndustryDD: global.common.IndustryDD,
      errors: {}, isCompanyRequired: true, isLastNameRequired: true, isMobileRequired: true,
      AssignTo: '',
      AssignToOption: '',
      clientId: global.config.clientId,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

    if (localStorage.getItem('isAdmin') !== null) {
      if (localStorage.getItem('isAdmin') === 'true') {
        this.setState({ isAdmin: true });
        this.getAccountList();
      }
    }
    const locationPath = window.location.pathname;
    const param = locationPath.split('/');
    this.setState({ id: param[2] });


    if (this.state.Company.length !== 0) {
      this.setState({ isCompanyRequired: true });
    } else { this.setState({ isCompanyRequired: false }); }



    if (this.state.Last_Name.length !== 0) {
      this.setState({ isLastNameRequired: true });
    } else { this.setState({ isLastNameRequired: false }); }


    if (param[2] !== '') {
      this.getData(param[2]);
    } else {
      console.log('Error here !');
    }
  }

  getAccountList = async () => {
    await axios.get(global.config.apiHost + "getAccountList/" + localStorage.getItem('access_token')).then((response) => {
      if (response.data.status === true) {
        if (response.data.data.length > 0) {
          let arr = response.data.data;
          const options = arr.map(d => ({
            "id": d.zoho_acc_id,
            "name": d.Account_Name
          }))
          options.unshift({ id: null, name: '-None-' });
          this.setState({ AssignToOption: options, loaded: true });
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }



  validateForm() {
    let errors = {};
    let formIsValid = true;

    if (this.state.Company === '') {
      formIsValid = false;
      errors["Company"] = "Please enter a Company.";
    } else {
      this.setState({ isButtonDisabled: false });
    }

    if (this.state.Last_Name === '') {
      formIsValid = false;
      errors["Last_Name"] = "Please enter a Last Name.";
    } else {
      this.setState({ isButtonDisabled: false });
    }

    // if (this.state.Mobile.length < 10 || this.state.Mobile.length > 10) {
    //   let mobile = /^\d{9}$/;
    //   if (this.state.Mobile !== 10 && !this.state.Mobile.match(mobile)) {
    //     formIsValid = false;
    //     errors["Mobile"] = "Please enter a valid Mobile Number.";

    //   }
    // }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleChange(event) {
    if (event.target.name === 'Company') {
      if (event.target.value.length === 0) {
        this.setState({ isCompanyRequired: true, errors: { Company: 'Please enter a Account Name.' } });
      } else { this.setState({ isCompanyRequired: false, errors: { Company: '' } }); }
    }


    if (event.target.name === 'Last_Name') {
      if (event.target.value.length === 0) {
        this.setState({ isLastNameRequired: true, errors: { Last_Name: 'Please enter a Account Name.' } });
      } else { this.setState({ isLastNameRequired: false, errors: { Last_Name: '' } }); }
    }

    // if (event.target.name === 'Mobile') {
    //   console.log(event.target.value.length);
    //   let mobile = /^\d{9}$/;
    //   if (event.target.value.length > 10 && !event.target.value.match(mobile)) {
    //     this.setState({ isMobileRequired: true, errors: { Mobile: 'Please enter a valid Mobile Number.' } });

    //   } else { this.setState({ isMobileRequired: false, errors: { Mobile: '' } }); }
    // }

    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    this.validateForm();
    this.setState({ isButtonDisabled: true });
    let arr = []; let arr1 = []; let trigger = ["approval", "workflow", "blueprint"]; let token = [localStorage.getItem('access_token')];

    arr.push(this.state);
    arr1 = ({ data: arr, trigger: trigger, token: token });
    if (this.state.Company !== '' && this.state.Last_Name !== '') {
      if (this.state.id !== '' && token !== '') {
        axios.post(global.config.apiHost + 'updateLead', arr1).then(function (response) {
          if (response.data.data[0].code === 'SUCCESS') {
            localStorage.setItem('success', 'updated');
            window.location.replace("/leads");
          } else {
            console.log('No getting server response');
            this.setState({ isButtonDisabled: false, message: '', flag: false, loaded: true });
          }
        }).catch(function (error) {
          console.log(error);
        })
      } else {
        console.log('error!');
        this.validateForm();
        this.setState({ isButtonDisabled: false, message: '', flag: false, loaded: true });
      }
    } else {
      this.validateForm();
      this.setState({ isButtonDisabled: false, message: '', flag: false, loaded: true });
    }
    event.preventDefault();
  }

  getData = async (id) => {
    await axios.get(global.config.apiHost + "getLeadsById/" + id + '/' + localStorage.getItem('access_token')).then((response) => {
      if (response.data.name !== "Error") {
        if (response.data.data.length > 0) {

          if (this.state.isAdmin === true) {
            axios.get(global.config.apiHost + "getAssignLeads/" + id).then((response) => {
              console.log(response.data);
              this.setState({ AssignTo: response.data[0].isAssign });
            }).catch((error) => {
              console.log(error)
            })
          }

          this.setState({
            First_Name: response.data.data[0].First_Name, Last_Name: response.data.data[0].Last_Name, Email: response.data.data[0].Email, Mobile: response.data.data[0].Mobile,
            State: response.data.data[0].State, Company: response.data.data[0].Company, Fax: response.data.data[0].Fax, Lead_Source: response.data.data[0].Lead_Source, Phone: response.data.data[0].Phone,
            Industry: response.data.data[0].Industry, Annual_Revenue: response.data.data[0].Annual_Revenue, Lead_Status: response.data.data[0].Lead_Status,
            Rating: response.data.data[0].Rating, Twitter: response.data.data[0].Twitter, No_of_Employees: response.data.data[0].No_of_Employees,
            Title: response.data.data[0].Title, Secondary_Email: response.data.data[0].Secondary_Email, Website: response.data.data[0].Website,
            Skype_ID: response.data.data[0].Skype_ID, Street: response.data.data[0].Street, Zip_Code: response.data.data[0].Zip_Code,
            City: response.data.data[0].City, Country: response.data.data[0].Country, Description: response.data.data[0].Description, loaded: true
          });
        }
      } else {
        console.log('No Data found!');
        // this.setState({ loaded: true, message: response.data.message });
      }
    }).catch((error) => {
      console.log(Object)
    })
  }

  render() {
    const { RatingDD, leadSourceDD, IndustryDD, LeadStatusDD } = this.state;
    let LeadStatusList = LeadStatusDD.length > 0
      && LeadStatusDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);

    let RatingList = RatingDD.length > 0
      && RatingDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);
    let IndustryList = IndustryDD.length > 0
      && IndustryDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);

    let leadSourceList = leadSourceDD.length > 0
      && leadSourceDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);

    let AccountNameList = this.state.AssignToOption.length > 0
      && this.state.AssignToOption.map((item, i) => {
        return (
          <option key={i} value={item.id}>{item.name}</option>
        )
      }, this);

    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        <div className="text-gray-900 bg-gray-200 p-4">
          <div className="grid grid-row-4 grid-flow-col pb-3">
            <div className="row-start-2 pt-2">
              {/* <h1 className="text-3xl">  Create Leads </h1> */}
              <ul className="flex text-gray-500 text-sm lg:text-base">
                <li className="inline-flex items-center">
                  <Link to="/users" className="font-medium text-blue-700">Home</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center">
                  <Link to="/leads" className="font-medium text-blue-700">Leads</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center">
                  Edit Leads
                </li>
              </ul>
            </div>
            <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to="/leads"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Back </button></Link>
            </div>
          </div>

          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
            <form onSubmit={this.handleSubmit}>
              <label className="block tracking-wide text-grey-darker text-base font-medium pb-3" htmlFor="Account Information"> Lead  Information</label>
              <hr className="p-2"></hr>
              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Company"> Company </label>
                  <input name='Company' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isCompanyRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} id="Company" type="text" placeholder="Enter Company name" value={this.state.Company}
                    onChange={this.handleChange} />
                  <div className="errorMsg text-red-500"> {this.state.errors.Company} </div>
                </div>

                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="First_Name"> First Name </label>
                  <input name='First_Name' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="First_Name" type="text" placeholder="Enter first name" value={this.state.First_Name}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="grid-zip"> Last Name </label>
                  <input name='Last_Name' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isLastNameRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} id="First_Name" type="text" placeholder="Enter last name" value={this.state.Last_Name}
                    onChange={this.handleChange} />
                  <div className="errorMsg text-red-500"> {this.state.errors.Last_Name} </div>
                </div>

                {this.state.isAdmin === true && <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="AssignTo"> Assign To </label>
                  <select name='AssignTo' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2  " id="AssignTo" value={this.state.AssignTo} onChange={this.handleChange}>
                    {AccountNameList}
                  </select>
                </div>}
                {/* <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Title"> Title </label>
                  <input name='Title' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Title" type="text" placeholder="Title" value={this.state.Title}
                    onChange={this.handleChange} />
                </div> */}
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Email"> Email </label>
                  <input name='Email' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Email" type="email" placeholder="Enter Email" value={this.state.Email}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Phone">Phone</label>
                  <input name='Phone' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Phone" type="text" placeholder="+91" value={this.state.Phone}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Fax">Fax</label>
                  <input name='Fax' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Fax" type="text" placeholder="Fax" value={this.state.Fax}
                    onChange={this.handleChange} />
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Mobile"> Mobile </label>
                  <input name='Mobile' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Mobile" type="text" placeholder="Mobile" value={this.state.Mobile}
                    onChange={this.handleChange} />
                  {/* <div className="errorMsg text-red-500"> {this.state.errors.Mobile} </div> */}
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Website"> Website </label>
                  <input name='Website' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Website" type="text" placeholder="www.partner-portal.com" value={this.state.Website}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Lead_Source"> Lead Source </label>
                  <select name='Lead_Source' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Lead_Source" value={this.state.Lead_Source} onChange={this.handleChange}>
                    {leadSourceList}
                  </select>
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Lead_Status"> Lead Status </label>
                  <select name='Lead_Status' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Lead_Status" value={this.state.Lead_Status} onChange={this.handleChange}>
                    {LeadStatusList}
                  </select>
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Industry"> Industry </label>
                  <select name='Industry' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Industry" value={this.state.Industry} onChange={this.handleChange}>
                    {IndustryList}
                  </select>
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="No_of_Employees"> No of Employees </label>
                  <input name="No_of_Employees" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="No_of_Employees" placeholder="No of Employees" type="text" value={this.state.No_of_Employees} onChange={this.handleChange} />
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Annual_Revenue"> Annual Revenue </label>
                  <input name="Annual_Revenue" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Annual_Revenue" type="text" placeholder="Annual Revenue" value={this.state.Annual_Revenue} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Rating"> Rating </label>
                  <select name='Rating' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Rating" value={this.state.Rating} onChange={this.handleChange}>
                    {RatingList}
                  </select>
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Skype_ID"> Skype ID </label>
                  <input name="Skype_ID" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Skype_ID" type="text" placeholder="Skype ID" value={this.state.Skype_ID} onChange={this.handleChange} />
                </div>
              </div>


              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Twitter"> Twitter </label>
                  <input name="Twitter" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Twitter" type="text" placeholder="Twitter" value={this.state.Twitter} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Secondary_Email"> Secondary Email </label>
                  <input name="Secondary_Email" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Secondary_Email" type="text" placeholder="Secondary Email" value={this.state.Secondary_Email} onChange={this.handleChange} />
                </div>
              </div>

              <label className="block tracking-wide text-grey-darker text-base font-medium pt-5 pb-3" htmlFor="Address Information"> Address Information</label>
              <hr className="p-2"></hr>

              <div className="-mx-3 md:flex mb-2 pt-2">

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Street">  Street </label>
                  <input name='Street' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Street" type="text" placeholder="Enter Street" value={this.state.Street}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="City"> City</label>
                  <input name='City' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="City" type="text" placeholder=" City" value={this.state.City} onChange={this.handleChange} />
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="State"> State </label>
                  <input name="State" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="State" type="text" placeholder=" State" value={this.state.State} onChange={this.handleChange} />
                </div>

              </div>
              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Zip_Code"> Zip Code </label>
                  <input name='Zip_Code' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Zip_Code" type="text" placeholder="Zip Code " value={this.state.Zip_Code}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Country">  Country </label>
                  <input name='Country' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Country" type="text" placeholder=" Country " value={this.state.Country}
                    onChange={this.handleChange} />
                </div>
              </div>

              <label className="block tracking-wide text-grey-darker text-base font-medium pt-5 pb-3" htmlFor="Description Information"> Description Information</label>
              <hr className="p-2"></hr>

              <div className="md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Description">Description </label>
                  <textarea name="Description" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Description" type="text" placeholder="Description" value={this.state.Description} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  {/* <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Created_Time">Created Time </label>
                  <input name="Created_Time" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Created_Time" type="text" placeholder="Created Time" value={this.state.Created_Time} onChange={this.handleChange} /> */}
                </div>
              </div>




              <span className="inline-flex justify-center">{this.state.message}</span>

              <div className="px-4 py-3 text-center sm:px-6">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" disabled={this.state.isButtonDisabled}> Save </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default EditLeads