import React, { Component } from 'react';
import axios from "axios";

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: { username: "", password: "", confirmPassword: "" },
      errors: {},
      message: "", status: false,
      clientId: global.config.clientId,
      hashKey: '',
      zoho_acc_id: '',
      pageUrl: '',
      pageTitle: 'Sign In',
      userEmail: '',
      buttonTitle: 'Login'
    };
    if (localStorage.getItem('access_token') !== null) {
      window.location = '/users';
    }
    this.changeHandler = this.changeHandler.bind(this);
    this.getCodeToken = this.getCodeToken.bind(this);
  }

  componentDidMount() {
    const locationPath = window.location.pathname;
    const param = locationPath.split('/');
    if (param[1] === 'create-user') {
      this.setState({ pageUrl: param[1], zoho_acc_id: param[2], hashKey: param[3], pageTitle: 'Create Your Login Credentials.', buttonTitle: 'Submit' });
      this.getUserEmail(param[2]);
    }
  }

  getUserEmail = async (zoho_acc_id) => {
    if (zoho_acc_id !== '' && zoho_acc_id !== undefined && zoho_acc_id !== null) {
      await axios({ method: 'post', url: global.config.apiHost + 'getUserEmail', data: { zoho_acc_id: zoho_acc_id } }).then(response => {
        if (response.data.status === true) {
          if (response.data.data[0].email !== undefined && response.data.data[0].email !== '') {
            this.setState({ userEmail: response.data.data[0].email });
          } else {
            this.showHideMessage('Sorry, Email not found!', response.data.status);
          }
        } else {
          this.showHideMessage(response.data.message, response.data.status);
        }
      }).catch(err => {
        console.log(err);
      });
    } else {
      this.showHideMessage('Sorry, URL not valid!', false);
    }
  }

  changeHandler = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });

    if (e.target.name === 'username') {
      if (e.target.value.length === 0) {
        this.setState({ errors: { username: 'Username is required!.' } });
      } else { this.setState({ errors: { username: '' } }); }
    }


    if (e.target.name === 'password') {
      if (e.target.value.length === 0) {
        this.setState({ errors: { password: 'Password is required!' } });
      } else { this.setState({ errors: { password: '' } }); }
    }

    if (e.target.name === 'confirmPassword' && this.state.pageUrl === 'create-user') {
      if (e.target.value.length !== 0 && fields['password'] !== fields['confirmPassword']) {
        this.setState({ errors: { confirmPassword: 'Password field does not match.' } });
      } else { this.setState({ errors: { confirmPassword: '' } }); }
    }
    // this.validateForm()
    //this.setState({ [e.target.name]: e.target.value })
  }

  getCodeToken = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      if (this.state.fields.username === 'admin' && this.state.fields.password === 'Portal#@1' && this.state.pageUrl !== 'create-user') {
        await axios.get(global.config.apiHost + 'codeExist/' + global.config.clientId).then(response => {
          if (response.data.length !== 0) {
            // If code exist then does not create code, Get all records from our database.
            localStorage.setItem('code', response.data[0].code);
            localStorage.setItem('access_token', response.data[0].access_token);
            localStorage.setItem('api_domain', response.data[0].api_domain);
            localStorage.setItem('refresh_token', response.data[0].refresh_token);
            localStorage.setItem('token_type', response.data[0].token_type);
            this.getAdminData();
          } else {
            // Open window for get code because this client code is not exist into database.
            window.open(global.config.authV2Url + 'auth?scope=' + global.config.scope + '&client_id=' + global.config.clientId + '&response_type=code&access_type=offline&redirect_uri=' + global.config.redirectUri);

            // After getting code get access_token, refresh_token
            var tokenData = setInterval(() => {
              if (localStorage.getItem('code') !== null) {
                clearInterval(tokenData);
                var code = localStorage.getItem('code');
                axios.get(global.config.apiHost + 'getToken/' + code).then(function (response) {
                  console.log(code);
                  if (response.data.length !== 0) {
                    localStorage.setItem('access_token', response.data[0].data.access_token);
                    localStorage.setItem('api_domain', response.data[0].data.api_domain);
                    localStorage.setItem('refresh_token', response.data[0].data.refresh_token);
                    localStorage.setItem('token_type', response.data[0].data.token_type);
                    this.getAdminData();
                  } else {
                    console.log('No getting server response');
                  }
                }).catch(function (error) {
                  console.log(error);
                });
              } else {
                console.log('Code not getting server response');
              }
            }, 1000);
          }
        }).catch(err => {
          console.log(err);
        });
      } else {
        if (this.state.fields.username !== '' && this.state.fields.password !== '') {
          // Database Login
          this.showHideMessage('');
          var sendUrl = '';
          if (this.state.pageUrl === 'create-user') {
            sendUrl = 'createUser';
          } else { sendUrl = 'loginUser'; }

          await axios({ method: 'post', url: global.config.apiHost + sendUrl, data: this.state }).then(response => {
            if (sendUrl === 'loginUser') {
              if (response.data.status === true) {
                let data = response.data.data;
                localStorage.setItem('code', data[0].code);
                localStorage.setItem('access_token', data[0].access_token);
                localStorage.setItem('api_domain', data[0].api_domain);
                localStorage.setItem('refresh_token', data[0].refresh_token);
                localStorage.setItem('userData', JSON.stringify(response.data.data));
                this.getApiDomain();
                window.location.href = global.config.hostUrl + 'users';
              } else {
                this.showHideMessage(response.data.message, false);
                this.refreshToken();
              }
            } else {
              if (response.data.status === true) {
                this.showHideMessage(response.data.message, response.data.status);
                window.location = '/';
                this.setState({ pageUrl: '', zoho_acc_id: '', hashKey: '', pageTitle: 'Sign In' });
              } else {
                this.showHideMessage(response.data.message, response.data.status);
              }
            }
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.showHideMessage('Please Enter Email & Password.', false);
        }
      }
    } else {
      console.log('Form Validation not complete');
    }

  };

  getApiDomain() {
    var apiDomain = '';
    if (localStorage.getItem('api_domain') !== null) {
      apiDomain = localStorage.getItem('api_domain')
    } else {
      apiDomain = 'https://www.zohoapis.eu/crm/v2/';
    }
    axios.post(global.config.apiHost + "getApiDomain", { apiDomain: apiDomain }).then((response) => {
      console.log('domain set succesfully!');
    }).catch((error) => {
      console.log(error);
    })
  }

  async refreshToken() {
    await axios.get(global.config.apiHost + "refreshToken/" + global.config.clientId).then((response) => {
      if (response.data.access_token !== '') {
        console.log('Token refreshed');
      } else {
        console.log('Token not refreshed');
      }
    }).catch((error) => {
      console.log(error);
    })
  }



  async getAdminData() {
    await axios.get(global.config.apiHost + "getUserData/" + localStorage.getItem('access_token') + '/users').then((response) => {
      if (response.data.name !== "Error") {
        if (response.data.users[0].id !== 0) {
          this.getApiDomain();
          localStorage.setItem('adminData', JSON.stringify(response.data));
          localStorage.setItem('isAdmin', 'true');
          console.log('000');

          window.location.href = global.config.hostUrl + 'users';
        }
      } else {
        console.log('111');
        this.getApiDomain();
        this.setState({ loaded: true, message: response.data.message });
        this.refreshToken();
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Please enter username.";
    }

    // if (typeof fields["email"] !== "undefined") {
    //   //regular expression for email validation
    //   var pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );
    //   if (!pattern.test(fields["email"])) {
    //     formIsValid = false;
    //     errors["email"] = "Please enter valid email-ID.";
    //   }
    // }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Please enter your password.";
    }

    if (fields['password'] !== fields['confirmPassword'] && this.state.pageUrl === 'create-user') {
      formIsValid = false;
      errors["confirmPassword"] = "Password field does not match";
    }
    // if (typeof fields["password"] !== "undefined") {
    //   if (
    //     !fields["password"].match(
    //       ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
    //     )
    //   ) {
    //     formIsValid = false;
    //     errors["password"] = "Please enter secure and strong password.";
    //   }
    // }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  showHideMessage = (message, status) => {
    this.setState({ message: message, status: status });
    setTimeout(() => { this.setState({ message: '', status: false }); }, 3000);
  }

  render() {
    //const { username, password } = this.state
    return (
      <section className="min-h-screen flex flex-col">
        <div className="flex flex-1 items-center justify-center">
          <div className="rounded-lg sm:border-2 px-4 lg:px-24 py-16 lg:max-w-xl sm:max-w-md w-full text-center">
            <form className="text-center" onSubmit={this.getCodeToken}>
              <h1 className="font-bold tracking-wider text-2xl mb-8 w-full text-gray-600"> {this.state.pageTitle}  </h1>

              {this.state.pageUrl === 'create-user' && <div className="py-2 text-left ">
                <input name="email" type="text" placeholder="Email address" className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 cursor-not-allowed" value={this.state.userEmail} readOnly />
              </div>}

              <div className="py-2 text-left">
                <input name="username" type="text" placeholder={this.state.pageUrl === 'create-user' ? 'Enter Username' : 'Enter Your Username'} className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700" value={this.state.fields.username} onChange={this.changeHandler} />
                <div className="errorMsg text-red-500"> {this.state.errors.username} </div>
                {this.state.message !== '' && this.state.message !== 'You have already used this link!' && this.state.pageUrl === 'create-user' &&
                  <div class={"errorMsg text-red-500"}>{this.state.message}</div>}
              </div>

              <div className="py-2 text-left">
                <input name="password" type="password" placeholder={this.state.pageUrl === 'create-user' ? 'Enter New Password' : 'Enter Your Password'} className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700" value={this.state.fields.password} onChange={this.changeHandler} />
                <div className="errorMsg text-red-500"> {this.state.errors.password}</div>
              </div>

              {this.state.pageUrl === 'create-user' && <div className="py-2 text-left">
                <input name="confirmPassword" type="password" placeholder="Confirm New Password" className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700" value={this.state.fields.confirmPassword} onChange={this.changeHandler} />
                <div className="errorMsg text-red-500"> {this.state.errors.confirmPassword}</div>
              </div>}

              {this.state.message !== '' && this.state.pageUrl !== 'create-user' && <div class="p-2">
                <div class={"text-" + (this.state.status === true ? 'green' : 'red') + "-700 p-1"} role="alert"><p>{this.state.message}</p></div>
              </div>}

              {this.state.message !== '' && this.state.pageUrl === 'create-user' && this.state.message === 'You have already used this link!' && this.state.pageUrl === 'create-user' &&
                <div class={"errorMsg text-red-500"}>{this.state.message}</div>}
              <div className="py-2">
                <button className="border-2 border-gray-100 focus:outline-none bg-blue-800 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-blue-700"
                > {this.state.buttonTitle}   </button>  {/* onClick={() => this.getCodeToken()}  */}
              </div>
            </form>
            {/* <div className="text-center">
              <Link to = "/forgot-password">
              <a className="hover:underline"> Forgot password? </a>
              </Link>
            </div>
            <div className="text-center mt-12">
              <span>Don't have an account?</span>
              <Link to = "/create-user"> 
              <a className="font-light text-md text-blue-800 underline font-semibold hover:text-blue-800"> Create One </a>
              </Link>
            </div> */}
          </div>
        </div>
      </section>);
  }
}
export default Login;