/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import axios from "axios";
import { Link } from 'react-router-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Spinner from '../../components/Loader';
const common = require('../../config/common');

class ConvertLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: '',
      Mobile: '',
      Stage: '',
      Amount: 0.00,
      loaded: true,
      OwnerId: '',
      leadId: '',
      leadStatus: '',
      flag: '',
      Deal_Name: '',
      Closing_Date: '',
      checked: false,
      message: '',
      Contact_Role: '',
      leadError: '',
      selected: false,
      contactMessage: '',
      accMessage: '',
      isExistData: [],
      isExistContactData: [],
      isExistingAccData: [],
      dataLength: 0,
      contactDataLength: 0,
      showModal: false,
      leadData: [],
      Accounts: 0,
      createAccount: '',
      invalidDataMsg: '',
      title: '',
      color: '',
      Account_Type: '',
      StageDD: common.StageDD,
      ContactRoleDD: common.ContactRoleDD,
      clientId: global.config.clientId,
      errors: {},
      isDealNameRequired: true, isClosingDateRequired: true, isStageRequired: true,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkBoxChange = this.checkBoxChange.bind(this);
    this.isSelectChange = this.isSelectChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.isAccIdChange = this.isAccIdChange.bind(this);
  }

  componentDidMount() {
    this.checkAccountExist();
    const locationPath = window.location.pathname;
    const param = locationPath.split('/');
    this.setState({ leadId: param[2] });
  }

  handleDayChange(Closing_Date) {
    Closing_Date = Closing_Date.toLocaleDateString();
    var monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var newDate = new Date(Closing_Date);
    var formattedDate = newDate.getFullYear() + '-' + monthNames[newDate.getMonth()] + '-' + newDate.getDate();
    Closing_Date = formattedDate;
    this.setState({ Closing_Date: Closing_Date });

    if (Closing_Date.length === 0) {
      this.setState({ isClosingDateRequired: true, errors: { Closing_Date: 'Please select a Closing Date.' } });
    } else { this.setState({ isClosingDateRequired: false, errors: { Closing_Date: '' } }); }

  }

  validateForm() {
    let errors = {};
    let formIsValid = true;

    if (this.state.Deal_Name === '') {
      formIsValid = false;
      errors["Deal_Name"] = "Please enter a Deal Name.";
    } else {
      this.setState({ isButtonDisabled: false });
    }
    if (this.state.Stage === '') {
      formIsValid = false;
      errors["Stage"] = "Please select Stage.";
    } else {
      this.setState({ isButtonDisabled: false });
    }
    if (this.state.Closing_Date === '') {
      formIsValid = false;
      errors["Closing_Date"] = "Please select a Closing Date.";
    } else {
      this.setState({ isButtonDisabled: false });
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleChange(event) {
    if (event.target.name === 'Deal_Name') {
      if (event.target.value.length === 0) {
        this.setState({ isDealNameRequired: true, errors: { Deal_Name: 'Please enter a Deal Name.' } });
      } else { this.setState({ isDealNameRequired: false, errors: { Deal_Name: '' } }); }
    }

    if (event.target.name === 'Stage') {
      if (event.target.value.length === 0) {
        this.setState({ isStageRequired: true, errors: { Stage: 'Please select a Stage.' } });
      } else { this.setState({ isStageRequired: false, errors: { Stage: '' } }); }
    }

    this.setState({
      [event.target.name]: event.target.value
    });
    // this.validateForm();
  }

  checkBoxChange() {
    this.setState({
      checked: !this.state.checked
    });
  }
  isSelectChange(event) {
    if (event.target.value === 'addInExisting') {
      let data = this.state.isExistData;
      this.setState({ accMessage: data.accMessage, message: '' });
    } else if (event.target.value === 'addNewAccount') {
      var leadData = JSON.parse(localStorage.getItem('leadData'));
      this.setState({ message: 'A new Contact ' + leadData.Full_Name + ' will be created for the Account.' });
      console.log('Account is also created for that lead!');
    } else {
      console.log(event.target.value);
    }
    this.setState({ createAccount: event.target.value });
  }

  isAccIdChange(event) {
    this.setState({ Accounts: event.target.value });
  }

  showHideMessage = (message) => {
    this.setState({ leadError: message });
    setTimeout(() => { this.setState({ leadError: '' }); }, 3000);
  }

  handleSubmit(event) {

    this.validateForm();

    const locationPathLeadId = window.location.pathname;
    var paramLeadId = locationPathLeadId.split('/');

    let data = {
      Stage: this.state.Stage,
      Amount: parseInt(this.state.Amount),
      Deal_Name: this.state.Deal_Name,
      Closing_Date: this.state.Closing_Date,
      leadId: this.state.leadId,
    }

    // this.validateForm();
    let resultArr = []; var deals;
    if (this.state.Accounts !== 0 && this.state.createAccount === 'addInExisting') {
      console.log('addInExisting');
      deals = [{ 'overwrite': true, "notify_lead_owner": true, "notify_new_entity_owner": true, "Accounts": this.state.Accounts, Deals: data }];
    } else if (this.state.Accounts === 0 && this.state.createAccount === 'addNewAccount') {
      console.log('addNewAccount');
      deals = [{ 'overwrite': true, "notify_lead_owner": true, "notify_new_entity_owner": true, Deals: data }];
    } else {
      deals = [{ 'overwrite': true, Deals: data }];
    }
    let token = [localStorage.getItem('access_token')];
    resultArr = ({ data: deals, token: token });
    this.setState({ isButtonDisabled: true, loaded: false });

    axios.post(global.config.apiHost + 'convertLead', resultArr).then(function (response) {

      var tokenData = setInterval(() => {

        if (response !== null) {
          clearInterval(tokenData);
          if (response.name !== undefined) {
            if (response.name !== "Error") {
              console.log('No error message');
            }
            // this.setState({ loaded: true });
          } else {
            if (response.data.data[0].status !== undefined) {
              if (response.data.data[0].status === 'error') {
                // if (response.data.data[0].message === 'duplicate data') {
                //   window.location = '/leads';
                // }
                localStorage.setItem('error', response.data.data[0].message);
              }
            } else {
              if (response.data.data[0].Accounts !== '') {
                var id = response.data.data[0].Accounts;
                axios.get(global.config.apiHost + "getAccountById/" + id + '/' + localStorage.getItem('access_token')).then((response) => {
                  if (response.data.name !== "Error") {
                    if (response.data.data.length > 0) {
                      var jsonData = {
                        Account_Type: 'Partner',
                        id: response.data.data[0].id
                      }
                      let arr = []; let arr1 = []; let trigger = ["approval", "workflow", "blueprint"]; let token = [localStorage.getItem('access_token')];

                      arr.push(jsonData);
                      arr1 = ({ data: arr, trigger: trigger, token: token });

                      axios.post(global.config.apiHost + 'updateCrmAccountType', arr1).then(function (response) {
                        if (response.data.data[0].code === 'SUCCESS') {
                          console.log('SUCCESS');

                          localStorage.setItem('success', 'converted');
                          localStorage.setItem('ComeFrom', 'edit-account');
                          localStorage.setItem('updatedAccId', response.data.data[0].details.id);
                          localStorage.setItem('convertedLeadId', paramLeadId[2]);

                          // Update converted BY
                          let userData = [];
                          if (localStorage.getItem('userData') !== null) {
                            userData = JSON.parse(localStorage.getItem('userData'));
                          } else {
                            userData = JSON.parse(localStorage.getItem('adminData'));
                          }

                          axios.post(global.config.apiHost + "updatedConvertedBy", { lead_id: localStorage.getItem('convertedLeadId'), converted_by: userData[0].zoho_acc_id }).then((response) => {
                          }).catch((error) => {
                            console.log(error);
                          })
                          // Update converted BY
                          window.location = "/leads";
                        } else { console.log('No getting server response'); }

                        this.setState({ loaded: true });

                      }).catch(function (error) {
                        console.log(error);
                      })
                    }
                  } else { console.log('No Data found!'); }
                }).catch((error) => {
                  console.log(error)
                })
                localStorage.setItem('success', 'converted');
                localStorage.setItem('leadResponse', JSON.stringify(response.data.data[0]));
                window.location = "/leads";
              } else {
                this.setState({ loaded: true });
                localStorage.setItem('error', 'Invalid Data!');
              }
            }
          }
        }
      }, 1000);
    }).catch(function (error) {
      console.log(error);
    })

    event.preventDefault();
  }

  checkAccountExist = async () => {
    if (localStorage.getItem('leadData') !== null) {
      var leadData = JSON.parse(localStorage.getItem('leadData'));
      this.setState({ leadData: leadData });

      await axios.get(global.config.apiHost + 'checkContactExist/' + leadData.Email + '/' + leadData.Company).then((response) => {
        if (response.data.status === true) {
          // this.setState({ contactMessage: response.data.contactMessage, isExistData: response.data, dataLength: response.data.accData.length, contactDataLength: response.data.contData.length });
          let contactData = response.data.contData;
          let accountData = response.data.accData;

          if (contactData.length > 0) {
            for (var i = 0; i < contactData.length; i++) {
              var parsedJson = JSON.parse(contactData[i].contact_json)
              Object.assign(contactData[i], { parseContactJson: parsedJson });
            };
          }

          if (accountData.length > 0) {
            for (var i = 0; i < accountData.length; i++) {
              var parsedJson = JSON.parse(accountData[i].account_json)
              Object.assign(accountData[i], { parseAccJson: parsedJson });
            };
          }

          this.setState({ contactMessage: response.data.contactMessage, isExistContactData: contactData, isExistingAccData: accountData, dataLength: accountData.length, contactDataLength: contactData.length });
        } else {
          console.log('Data not found');
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  render() {
    const { data } = this.props;
    const { StageDD, ContactRoleDD } = this.state;

    let stageList = StageDD.length > 0 && StageDD.map((item, i) => {
      return (
        <option key={i} value={item.value}>{item.name}</option>
      )
    }, this);

    let roleList = ContactRoleDD.length > 0 && ContactRoleDD.map((item, i) => {
      return (
        <option key={i} value={item.value}>{item.name}</option>
      )
    }, this);

    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        {data}
        <div className="text-gray-900 bg-gray-200 p-4">
          {/* <div className="grid grid-row-4 grid-flow-col ">
            <div className="row-start-2 p-4"><h1 className="text-3xl"> Convert Leads </h1></div>
            <div className="row-start-2 col-end-4 p-4 flex flex-row-reverse">
              <Link to={"/leads"}><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Back </button></Link>
            </div>
          </div> */}

          <div className="grid grid-row-4 grid-flow-col pb-4">
            <div className="row-start-2 pt-2">
              {/* <h3 className="text-2xl"> Leads </h3> */}
              <ul className="flex text-gray-500 text-sm lg:text-base">
                <li className="inline-flex items-center">
                  <Link to="/users" className="font-medium text-blue-700">Home</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center">
                  <Link to="/leads" className="font-medium text-blue-700">Leads</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center">
                  <Link to={'/lead-details/' + this.state.leadId} className="font-medium text-blue-700">Lead Details</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20"> <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                </li>
                <li class="inline-flex items-center"> Convert Lead</li>
              </ul>
            </div>
            <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to={'/lead-details/' + this.state.leadId}><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Back </button></Link>
            </div>
          </div>

          <div class="bg-white shadow-md rounded px-6 pt-6 flex flex-col">

            {this.state.accMessage !== '' && <span className="row-start-2 p-4 "> {this.state.accMessage}</span>}
            {this.state.contactMessage !== '' && <span className="row-start-2 p-4 "> {this.state.contactMessage}</span>}
            {/* {(this.state.contactDataLength.length === 0 && this.state.isExistingAccData.length === 0) && <div> */}
            {this.state.isExistingAccData.length === 0 && <div className="row-start-2 p-2"> Create New <b>Account:</b> {this.state.leadData.Company}</div>}
            {this.state.contactDataLength.length === 0 && <div className="row-start-2 p-2"> Create New Contact: 	{this.state.leadData.Full_Name}</div>}
            {/* </div>} */}
            {(this.state.isExistContactData.length !== 0 || this.state.isExistingAccData.length !== 0) && <div>
              <div className="mt-4 space-y-4 p-2">
                <div className="flex items-center">
                  <input id="push_everything" name="radio" type="radio" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" value='addInExisting' onChange={this.isSelectChange} />
                  <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                    Add to existing Account.
                  </label>&nbsp; &nbsp;
                  <a href="javascript:void(0);" className="font-medium text-indigo-600 hover:text-indigo-500" onClick={() => this.setState({ showModal: true })}> View </a>
                </div>
              </div>

              <div className="mt-4 space-y-4 p-2">
                <div className="flex items-center">
                  <input id="radio2" name="radio" type="radio" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" value='addNewAccount' onChange={this.isSelectChange} />
                  <label htmlFor="radio2" className="ml-3 block text-sm font-medium text-gray-700">
                    Create New Account
                  </label>
                </div>
                <span className="row-start-2 p-4"> {this.state.message}</span>
              </div>
            </div>}
            <hr className="mt-4"></hr>
            {this.state.showModal ? (
              <>
                <div
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none h-50" >
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 className="text-2xl font-semibold"> Matching Records </h3>
                        <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={() => this.setState({ showModal: true })}><span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"></span> </button>
                      </div>
                      {/*body*/}

                      {(this.state.isExistContactData.length !== 0 && this.state.isExistContactData !== null) && <div className="relative p-4 flex-auto">
                        <div className="flex justify-center">
                          <table className="w-full text-md bg-white shadow-md rounded mb-4 table-fixed">
                            <tbody>
                              <tr className="border-b">
                                <th className="w-1/6 text-left p-1 px-2"></th>
                                <th className="w-1/2 text-left p-3 px-5">Email</th>
                                <th className="w-1/2 text-left p-3 px-5">Account Name</th>
                                {/* <th className="w-1/4 text-left p-3 px-5">Website</th> */}
                                {/* <th className="w-1/4 text-left p-3 px-5">Phone</th> */}
                              </tr>

                              {this.state.length !== 0 && this.state.isExistContactData.map((item, index) => (
                                <tr className="border-b hover:bg-orange-100 bg-gray-100" key={index}>
                                  <td className="p-3 px-5 flex">
                                    <input id="acc-radio-button" name="acc-radio-button" type="radio" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" value={item.zoho_id} onChange={this.isAccIdChange} />
                                  </td>
                                  <td className="p-3 px-5">{item.parseContactJson.Email == null ? <span className="text-center">-</span> : item.parseContactJson.Email}</td>
                                  <td className="p-3 px-5">{item.parseContactJson.Account_Name.name == null ? <span className="text-center">-</span> : item.parseContactJson.Account_Name.name}</td>
                                </tr>))}
                            </tbody>
                          </table>
                        </div>
                      </div>}

                      {(this.state.isExistingAccData.length !== 0 && this.state.isExistingAccData !== null) && <div className="relative p-4 flex-auto">
                        <div className="flex justify-center">
                          <table className="w-full text-md bg-white shadow-md rounded mb-4 table-fixed">
                            <tbody>
                              <tr className="border-b">
                                <th className="w-1/6 text-left p-1 px-2"></th>
                                <th className="w-1/2 text-left p-3 px-5">Account Id</th>
                                <th className="w-1/2 text-left p-3 px-5">Account Name</th>
                                {/* <th className="w-1/4 text-left p-3 px-5">Website</th> */}
                                <th className="w-1/4 text-left p-3 px-5">Phone</th>
                              </tr>
                              {this.state.length !== 0 && this.state.isExistingAccData.map((item, index) => (
                                <tr className="border-b hover:bg-orange-100 bg-gray-100" key={index}>
                                  <td className="p-3 px-5 flex">
                                    <input id="acc-radio-button" name="acc-radio-button" type="radio" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" value={item.zoho_acc_id} onChange={this.isAccIdChange} />
                                  </td>
                                  <td className="p-3 px-5">{item.zoho_acc_id == null ? <span className="text-center">-</span> : item.zoho_acc_id}</td>

                                  <td className="p-3 px-5">{item.Account_Name == null ? <span className="text-center">-</span> : item.Account_Name}</td>

                                  <td className="p-3 px-5">{item.parseAccJson.Phone == null ? <span className="text-center">-</span> : item.parseAccJson.Phone}</td>
                                </tr>))}
                            </tbody>
                          </table>
                        </div>
                      </div>}

                      {/*footer*/}
                      <div className="flex items-center justify-start p-3 border-t border-solid border-blueGray-200 rounded-b">
                        <button className="bg-blue-500 text-white active:bg-blue-600 text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({ showModal: false })}> Done </button>
                        <button className="text-gray-500 bg-gray-200 px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border ml-2" type="button" onClick={() => this.setState({ showModal: false })}> Cancel </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}

            <div className="bg-white rounded px-0 pt-0 pb-2 flex flex-col my-2">
              <label className="inline-flex items-center mt-3">
                <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={this.state.checked} onChange={this.checkBoxChange}></input><span className="ml-2 text-gray-700"> Create a new Deal for this Account.</span>
              </label>
            </div>

            <form onSubmit={this.handleSubmit}>
              {this.state.checked && <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                {this.state.leadError !== '' && <span className="row-start-2 p-4 text-red-500"> {this.state.leadError}</span>}
                <div className="-mx-3 md:flex mb-2 pt-2">
                  <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                    <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="Company"> Amount </label>
                    <input name='Amount' className="block w-full bg-grey-lighter text-grey-darker border rounded py-2 px-2 focus:outline-none" id="Amount" type="text" placeholder="Enter Amount" value={this.state.Amount} onChange={this.handleChange} />
                  </div>

                  <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                    <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="First_Name"> Deal Name </label>
                    <input name='Deal_Name' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isDealNameRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} id="Deal_Name" type="text" placeholder="Enter Deal name" value={this.state.Deal_Name}
                      onChange={this.handleChange} />
                    <div className="errorMsg text-red-500"> {this.state.errors.Deal_Name} </div>
                  </div>
                  <div className="md:w-1/4 px-3">
                    <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="grid-zip"> Closing Date</label>
                    <div className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isClosingDateRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")}>
                      <DayPickerInput placeholder="YYYY-MM-DD" format="YYYY/MM/DD" dayPickerProps={{ modifiers: { disabled: [{ before: new Date() }] } }} value={this.state.Closing_Date} onDayChange={this.handleDayChange} />
                    </div>
                    <div className="errorMsg text-red-500"> {this.state.errors.Closing_Date} </div>
                  </div>
                  <div className="md:w-1/4 px-3">
                    <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Stage"> Stage </label>
                    <select name='Stage' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isStageRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} id="Stage" value={this.state.Stage} onChange={this.handleChange} x>
                      {stageList}
                    </select>
                    <div className="errorMsg text-red-500"> {this.state.errors.Stage} </div>
                  </div>
                </div>

                <div className="-mx-3 md:flex mb-2 pt-2">
                  <div className="md:w-1/4 px-3">
                    <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Contact_Role"> Contact Role </label>
                    <select name='Contact_Role' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2 " id="Contact_Role" value={this.state.Contact_Role} onChange={this.handleChange} >
                      {roleList}
                    </select>
                  </div>
                </div>

              </div>}
              <div className="px-4 py-3 text-center sm:px-6">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" disabled={this.state.isButtonDisabled}> Convert </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    )
  }
}
export default ConvertLeads;




