import React from "react";
import "../../config/config";

class CreateUser extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: { firstName: "", lastName: "", email: "", password: "" },
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm =
      this.submituserRegistrationForm.bind(this);
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    console.log(this.validateForm());

    e.preventDefault();
    if (this.validateForm()) {
      console.log(this.state.fields);

      // axios
      // .post("", this.state.fields)
      // .then((Response) => {
      //   console.log(Response);
      // })
      // .catch((error) => {
      //   console.log(Object);
      // })
      alert("Form submitted");
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter your firstName.";
    }

    if (typeof fields["firstName"] !== "undefined") {
      if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["firstName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "*Please enter your lastName.";
    }

    if (typeof fields["lastName"] !== "undefined") {
      if (!fields["lastName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["lastName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof fields["password"] !== "undefined") {
      if (
        !fields["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    return (
      <section className="min-h-screen flex flex-col">
        <div className="flex flex-1 items-center justify-center">
          <div className="rounded-lg sm:border-2 px-4 lg:px-24 py-16 lg:max-w-xl sm:max-w-md w-full text-center">
            <form
              className="text-center"
              onSubmit={this.submituserRegistrationForm}
            >
              <h1 className="font-bold tracking-wider text-3xl mb-8 w-full text-gray-600">
                New User
              </h1>
              <div className="py-2 text-left grid grid-cols-2 gap-2 place-content-center">
                <input
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700"
                  value={this.state.fields.firstName}
                  onChange={this.handleChange}
                />
                <input
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700"
                  value={this.state.fields.lastName}
                  onChange={this.handleChange}
                />
                <div className="errorMsg text-red-500">
                  {this.state.errors.firstName}
                </div>
                <div className="errorMsg text-red-500">
                  {this.state.errors.lastName}
                </div>
              </div>

              <div className="py-2 text-left">
                <input
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700"
                  value={this.state.fields.email}
                  onChange={this.handleChange}
                />
                <div className="errorMsg text-red-500">
                  {this.state.errors.email}
                </div>
              </div>
              <div className="py-2 text-left">
                <input
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700"
                  value={this.state.fields.password}
                  onChange={this.handleChange}
                />
                <div className="errorMsg text-red-500">
                  {this.state.errors.password}
                </div>
              </div>
              <div className="py-2">
                {/* <button className="border-2 border-gray-100 focus:outline-none bg-purple-600 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700">
                    
                    Sign Up
                  </button> */}
                <input
                  type="submit"
                  className="border-2 border-gray-100 focus:outline-none bg-blue-800 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-blue-700"
                  value="Sign Up"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}
export default CreateUser;
