import React, { Component } from 'react';
import Header from '../../components/layout/Header'
import axios from "axios";
import Toast from '../../components/toast/Toast'
import Spinner from '../../components/Loader';
import { Link } from 'react-router-dom';
import Skeleton from '../../components/skeleton/Skeleton';
import { PencilAltIcon, MailIcon, MailOpenIcon, UserAddIcon, CheckIcon } from '@heroicons/react/outline';
import { UserCircleIcon, KeyIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
// import eventBus from "../EventBus";

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = { success: false, skeleton: false, flag: false, jsonData: [], loaded: false, message: '', deleteID: '', emailModel: false, sendEmail: '', sendId: '', isAdmin: false, loginData: [], isSendingMail: false, mailSend: false, mailMessage: '', page: 1, per_page: 10, more_records: false };
    if (localStorage.getItem('code') == null) {
      window.location = '/';
    }
  } // constructor

  componentDidMount() {
    this.getData();
    if (localStorage.getItem('success') === 'inserted') {
      this.setState({ message: 'Record Inserted Successfully', success: true, });
    }
    if (localStorage.getItem('success') === 'updated') {
      this.setState({ message: 'Record Updated Successfully', success: true, });
    }
  }

  // applyCoupon = () => {
  //   console.log("applying coupone");
  //   eventBus.dispatch("couponApply", { message: "coupone applied" });
  // };


  onPaging = async (name) => {
    if (name === 'prev') {
      this.state.page = this.state.page - 1;
    } else { this.state.page = this.state.page + 1; }
    this.setState({ page: this.state.page, per_page: this.state.per_page });
    this.getData();
  }

  getData = async () => {
    this.setState({ page: this.state.page, per_page: this.state.per_page, loaded: true });
    await axios.get(global.config.apiHost + "getAccount/" + localStorage.getItem('access_token') + '/Accounts/' + this.state.page + '/' + this.state.per_page).then((response) => {
      var arrResult = [];
      if (response.data.name !== "Error") {
        if (response.data.data.length > 0) {
          let arr = response.data.data;
          this.setState({ more_records: response.data.info.more_records });
          if (localStorage.getItem('isRefresh') === null) {
            // First Time
            arrResult = arr.filter((list) => list.Account_Type === 'Partner');
            axios.post(global.config.apiHost + 'addAccounts', { arr: arrResult, clientId: global.config.clientId })
              .then(res => console.log('data sent.'))
              .catch(err => console.log(err.data))
          } else {
            console.log('Second Time');
            localStorage.setItem('isRefresh', 'true');
          }
          axios.get(global.config.apiHost + "getLogins").then((response) => {
            if (response.data.data.length > 0) {
              let loginData = response.data.data;
              for (var i = 0; i < arr.length; i++) {
                for (var j = 0; j < loginData.length; j++) {
                  if (arr[i].id === loginData[j].zoho_acc_id) {
                    Object.assign(arr[i], { username: loginData[j].username, password: loginData[j].password, email: loginData[j].email });
                  }
                }
              };
              // arrResult = arr.filter((list) => list.Account_Type === 'Partner');
              // this.dbUpdate(arrResult);
              this.setState({ jsonData: arr, loaded: true, message: '' });
            } else {
              this.setState({ jsonData: arr, loaded: true, message: 'No result found.' });
            }
          }).catch((error) => {
            console.log(error)
          });
        }
      } else {
        this.setState({ jsonData: [], loaded: true, message: response.data.message, skeleton: true });
        global.config.logout();
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  // Send email function 
  sendInviteMail = async (email, accId) => {
    var hashKey = global.common.hashKey();
    var Link = global.config.hostUrl + 'create-user/' + accId + '/' + hashKey;
    let obj = {
      email: email,
      link: Link
    }
    if (email === '') {
      this.setState({ isSendingMail: false });
    } else {
      this.setState({ isSendingMail: true });
      await axios.post(global.config.apiHost + "sendEmail", obj).then((response) => {
        if (response.data.status === true) {
          this.setState({ mailMessage: 'Email Sent Successfully!', success: true, mailSend: true, isSendingMail: false });
        } else {
          this.setState({ mailMessage: 'Email is not send.', success: false, mailSend: false, isSendingMail: false });
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  dbUpdate = async (data) => {
    if (localStorage.getItem('ComeFrom') !== null) {
      if (localStorage.getItem('ComeFrom') === 'edit-account') {
        if (localStorage.getItem('updatedAccId') !== null) {
          // Update in db
          axios.post(global.config.apiHost + 'updateAccountDb', { arr: data, clientId: global.config.clientId })
            .then(function (res) {
              if (res.data.status === true) {
                localStorage.removeItem('ComeFrom');
                localStorage.removeItem('updatedAccId');
              }
            }).catch(err => { console.log(err.data); });
        }
      }
    }
  }

  render() {
    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        <div className="text-gray-900 bg-gray-200 p-4">
          <div className="grid grid-row-4 grid-flow-col pb-4">
            <div className="row-start-2 ">
              <ul className="flex text-gray-500 text-sm lg:text-base pt-2">
                <li className="inline-flex items-center">
                  <Link to="/" className="font-medium text-blue-700">Home</Link>
                  <ChevronRightIcon className="h-5 w-auto text-gray-400" />
                </li>
                <li className="inline-flex items-center"> Account </li>
              </ul>
            </div>
            <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to="/add-account"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Create Account </button></Link>
              {/* <button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" onclick={this.applyCoupon()}> Create Account </button> */}

            </div>
          </div>
          {this.state.message !== '' && <Toast message={this.state.message} success={this.state.success} />}

          <div className="flex justify-center">
            <table id="example" className="w-full text-md bg-white shadow-md rounded mb-4">
              <thead>
                <tr className="border-b">
                  <th data-priority="1" className="text-left p-3 pl-3 px-2"></th>
                  <th data-priority="2" className="text-left p-3 px-5">Account Name</th>
                  <th data-priority="3" className="text-left p-3 px-5">Email</th>
                  <th data-priority="4" className="text-left p-3 px-5">Phone</th>
                  <th data-priority="5" className="text-left p-3 px-5">Website</th>
                  <th data-priority="6" className="text-left p-3 px-5">Account Owner </th>
                  <th data-priority="7" className="text-left p-3 px-5 flex justify-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.jsonData.length !== 0 && this.state.jsonData.map((item, index) => (
                  <tr className="border-b hover:bg-blue-100 bg-gray-100 " key={index}>
                    <td className="p-2 pl-2 px-2 text-green-700">{item.Account_Type === 'Partner' && <UserCircleIcon className="h-4 w-4" />}

                      {item.Account_Type === 'Partner' && <div className={(item.username !== '' && item.password !== '' ? 'text-green-700' : 'text-red-500')}><KeyIcon className="h-4 w-4" /></div>}
                    </td>
                    <td className="p-2 px-0">{item.Account_Name === null ? <span className="text-center">-</span> : item.Account_Name}</td>
                    
                    {item.Account_Type === 'Partner' && <td className="p-2 px-5">{(item.email === null || item.email === '' || item.email === undefined) ? <span className="text-center">-</span> : item.email}</td>}

                    {item.Account_Type !== 'Partner' && <td className="p-2 px-5">{(item.Email === null || item.Email === '' || item.Email === undefined) ? <span className="text-center">-</span> : item.Email}</td>}

                    <td className="p-2 px-5">{item.Phone === null ? <span className="text-center">-</span> : item.Phone}</td>
                    <td className="p-2 px-5"> {item.Website === null ? <span className="text-center">-</span> : item.Website}</td>
                    <td className="p-2 px-5"> {item.Owner.name === null ? <span className="text-center">-</span> : item.Owner.name}</td>
                    <td className="p-2 px-5 flex justify-center">

                      {item.Account_Type !== 'Partner' && <Link to={'/edit-account/' + item.id + ((item.Email !== '' && item.Email !== null && item.Email !== undefined) ? '/'+ item.Email : '') }><button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-blue-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-blue-200 "><PencilAltIcon className="h-4 w-4" /></button></Link>}

                      {item.Account_Type === 'Partner' && <Link to={'/edit-account/' + item.id + (item.email !== '' ? '/'+ item.email : '')}><button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-blue-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-blue-200 "><PencilAltIcon className="h-4 w-4" /></button></Link>}
                  
                      {item.Account_Type === 'Partner' && item.password === '' && <button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-red-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-red-200 rounded" onClick={() => this.setState({ sendEmail: item.email, sendId: item.id, emailModel: true })}> <MailIcon className="h-4 w-4" />
                      </button>}

                      {item.Account_Type === 'Partner' && item.username !== '' && item.password !== '' && <button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-red-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-red-200 rounded opacity-50 cursor-not-allowed"><MailOpenIcon className="h-4 w-4" /></button>}


                      {(item.Account_Type !== 'Partner') && <button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-blue-700 transition-colors duration-150 bg-white rounded-full focus:outline-none hover:bg-blue-200 rounded opacity-50 cursor-not-allowed"><UserAddIcon className="h-4 w-4"></UserAddIcon> </button>}
                    </td>
                  </tr>
                ))}
                {this.state.jsonData.length === 0 && this.state.skeleton === false && <Skeleton value={7} />}
                {this.state.jsonData.length === 0 && this.state.skeleton === true && <tr className="text-center">
                  <td colSpan="8" className="p-3 px-5">{this.state.message}</td>
                </tr>}
              </tbody>
            </table>
          </div>

          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                {/* Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                <span className="font-medium">97</span> results */}
                Page Number : {this.state.page}
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button className={'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none' + (this.state.page !== 1 ? ' ' : ' opacity-50 cursor-not-allowed focus:outline-none')} onClick={() => this.onPaging('prev')}>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="">Previous</span>
                </button>
                <button className={'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none' + (this.state.more_records === true ? ' ' : ' opacity-50 cursor-not-allowed focus:outline-none')} onClick={() => this.onPaging('next')}>
                  <span className=""> Next</span><ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>

        </div>
        {this.state.emailModel === true && <div className="fixed z-10 inset-0 overflow-y-auto" aria-ledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="text-center p-5 flex-auto justify-center">
                    {this.state.mailSend === true && <CheckIcon className="w-20 h-16 flex items-center text-green-500 mx-auto" />}
                    {this.state.mailSend === false && <MailIcon className="w-20 h-16 flex items-center text-red-500 mx-auto" />}
                    {this.state.mailSend === false && <h2 className="text-xl font-bold py-4">{this.state.sendEmail !== '' ? 'Partner Login Invitation' : 'No Email Address Found!'}</h2>}
                    {this.state.mailSend === false && <p className="text-sm text-gray-500 px-8">{this.state.sendEmail !== '' ? 'Send portal invitation to ' + this.state.sendEmail : 'Please add an email address to the account for registration process.'} </p>}

                    {this.state.mailSend === true && <p className="text-sm text-gray-500 px-8">{this.state.mailMessage} </p>}
                  </div>
                </div>
              </div>

              <div className="p-3  mt-2 text-center space-x-4 md:block">

                {this.state.sendEmail !== '' && this.state.mailSend === false && <button className={"mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600 " + (this.state.isSendingMail === true ? 'cursor-wait' : '')} onClick={() => this.sendInviteMail(this.state.sendEmail, this.state.sendId)}>Proceed</button>}

                {this.state.sendEmail !== '' && this.state.mailSend === true && <button className={"mb-2 md:mb-0 bg-green-500 border border-green-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-600 "} onClick={() => this.setState({ mailSend: false, sendEmail: '', sendId: '', emailModel: false })}>Close</button>}

                {this.state.mailSend === false && <button className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100" onClick={() => this.setState({ sendEmail: '', sendId: '', emailModel: false })}> {this.state.sendEmail !== '' ? 'Cancel' : 'Thanks'} </button>}
              </div>
            </div>
          </div>
        </div>
        }
      </div >
    )
  }
}

export default Account;