import React, { Component } from 'react'

export class Skeleton extends Component {

    constructor(props){
        super(props)
        this.state = {value: this.props.value, skeletonData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] , skeltonTH: []}
        for(var i = 0; i < this.props.value; i++){ this.state.skeltonTH.push(i); }
    }
    
    render() {
        return (
           <>
            {this.state.skeletonData.map((index) => (<tr className="border-b" key={index}>
                {this.state.skeltonTH.map((index) => (<th className=" p-3 px-5" key={index}>
                    <div className="h-4 w-30 bg-gray-200 rounded animate-pulse"></div>
                </th>))}
            </tr>))}
           </>
        )
    }
}

export default Skeleton
