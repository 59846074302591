import React from 'react'
import Loader from "react-loader";
// import "./loader/Loader.css"
function Spinner(props) {
    return (
        <div>
            {/* <Loader loaded={props.loaded} lines={20} length={10} width={10} radius={70}
    corners={1} rotate={0} direction={1} color="#1E3A8A" speed={1}
    trail={100} shadow={true} hwaccel={true} className="spinner"
    zIndex={2e9} top="50%" left="50%" scale={1.00}
    loadedClassName="loadedContent" /> */}
    <Loader loaded={props.loaded} lines={13} length={25} width={5} radius={25}
    corners={1} rotate={0} direction={1} color="#1E3A8A" speed={1}
    trail={60} shadow={true} hwaccel={true} className="spinner"
    zIndex={2e9} top="50%" left="50%" scale={1.00}
    loadedClassName="loadedContent" />
        </div>
    )
}

export default Spinner
