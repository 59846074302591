import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../../config/config';

class Header extends Component {
  constructor(props) {
    super(props)
    if (localStorage.getItem('access_token') === null) {
      window.location = '/';
    }
    var pathname = window.location.pathname;
    this.state = { pathname: pathname.split('/')[1], isOpen: false, isAdmin: false, activeCss: 'mr-6 p-1 border-b-2 border-white-500 font-semibold' };
  }

  componentDidMount() {
    if (localStorage.getItem('isAdmin') !== null) {
      if (localStorage.getItem('isAdmin') !== '') {
        this.setState({ isAdmin: true });
      }
    } else {
      this.setState({ isAdmin: false });
    }
  }

  logout = () => {
    localStorage.clear();
    window.location = '/';
  }

  ddMenu = () => {
    if (this.state.isOpen === false) {
      this.setState({ isOpen: true });
    } else {
      this.setState({ isOpen: false });
    }
    console.log('dd menu clicked');
  }

  render() {
    return (
      <div>
        <div className="flex justify-between items-center py-4 bg-blue-900">
          <div className="flex-shrink-0 ml-10 cursor-pointer">
            <i className="fas fa-drafting-compass fa-2x text-white-500"></i>
            <span className="ml-1 text-3xl text-blue-200 font-normal ">{global.config.appName}</span>
          </div>

          <i className="fas fa-bars fa-2x visible md:invisible mr-10 md:mr-0 text-blue-200 cursor-pointer"></i>
          <ul className="hidden md:flex overflow-x-hidden mr-10 font-sm">
            <li className={this.state.pathname === 'users' ? this.state.activeCss : 'mr-6 p-1'}>
              <Link to="users"> <a className="text-white hover:text-blue-300 "> Home </a></Link>
            </li>
            <li className={(this.state.pathname === 'leads' || this.state.pathname === 'add-leads' || this.state.pathname === 'edit-leads' || this.state.pathname === 'convert-leads' || this.state.pathname === 'lead-details') ? this.state.activeCss : 'mr-6 p-1'}>
              <Link to="/leads"> <a className="text-white hover:text-blue-300"> Leads </a></Link>
            </li>

            <li className={(this.state.pathname === 'contacts' || this.state.pathname === 'add-contacts' || this.state.pathname === 'edit-contacts') ? this.state.activeCss : 'mr-6 p-1'}>
              <Link to="/contacts"> <a className="text-white hover:text-blue-300"> Contacts </a></Link>
            </li>

            <li className={(this.state.pathname === 'deals' || this.state.pathname === 'add-deals' || this.state.pathname === 'edit-deals') ? this.state.activeCss : 'mr-6 p-1'}>
              <Link to="/deals"> <a className="text-white hover:text-blue-300"> Deals </a></Link>
            </li>

            {this.state.isAdmin === true && <li className={(this.state.pathname === 'account' || this.state.pathname === 'add-account' || this.state.pathname === 'edit-account' || this.state.pathname === 'update-login') ? this.state.activeCss : 'mr-6 p-1'}>
              <Link to="/account"> <a className="text-white hover:text-blue-300"> Account </a></Link>
            </li>}

            {this.state.isAdmin === true && <li className={(this.state.pathname === 'quotes' || this.state.pathname === 'add-quote' || this.state.pathname === 'edit-quote') ? this.state.activeCss : 'mr-6 p-1'}>
              <Link to="/quotes"> <a className="text-white hover:text-blue-300"> Quotes </a></Link>
            </li>}

            {/* <li className={(this.state.pathname === 'product' || this.state.pathname === 'add-product' || this.state.pathname === 'edit-product') ? 'mr-6 p-1 border-b-2 border-orange-500' : 'mr-6 p-1'}>
                <Link to = "/product"> <a className="text-white hover:text-blue-300"> Product </a></Link>
              </li> */}
            {/* <li className=" mr-6 bg-white rounded-full p-1  " onClick={() => this.logout()} >
                <Link to = "#"><a className="text-blue text-xs hover:text-blue-300 p-2" >Logout</a></Link>
              </li> */}

            <li className=" mr-6 bg-white rounded-full p-1">
              <button className="font-semibold py-1 px-2 rounded inline-flex items-center focus:outline-none" onClick={() => this.ddMenu()}>
                <span className="mr-1"> {this.state.isAdmin === true ? 'Admin' : 'Partner'}</span>
                {this.state.isOpen === true && <svg xmlns="http://www.w3.org/2000/svg" className="fill-current h-4 w-4" viewBox="0 0 20 20" fill="currentColor"> <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" /></svg>}
                {this.state.isOpen === false && <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>}
              </button>


              {this.state.isOpen === true && <ul className="absolute text-gray-700 pt-2 group-hover:block">
                <li className="">
                  <button className=" font-semibold py-2 px-4 rounded inline-flex items-center bg-gray-400 focus:outline-none" onClick={() => this.logout()}>
                    <span className="text-white"> Logout </span>
                  </button>
                </li>
              </ul>}
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default Header;