import React, { Component } from 'react'
import Header from '../../components/layout/Header'
import axios from "axios";
import { Link } from 'react-router-dom';

class UpdateLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      clientId: global.config.clientId,
      message: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } // constructor

  componentDidMount() {
    const locationPath = window.location.pathname;
    const param = locationPath.split('/');
    this.setState({ id: param[2] });
    if (param[2] !== '') {
      this.getData(param[2]);
    } else { console.log('Error here !'); }
  }

  handleChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === 'username' && event.target.value.length !== 0) {
      var messageResponse = '';
      await axios.post(global.config.apiHost + 'checkUsername', { username: event.target.value }).then(function (response) {
        messageResponse = response.data.message;
        // if (response.data.status === false) {

        // } else {  

        // }
      }).catch(function (error) {
        console.log(error);
      })
      this.setState({ message: messageResponse });
    }
  }

  getData = async (zoho_acc_id) => {
    var data = [];
    await axios.get(global.config.apiHost + "getAccountByIdMYSQL/" + zoho_acc_id).then((response) => {
      if (response.data.name !== "Error") {
        if (response.data.data.length > 0) {
          data = response.data.data[0];
          this.setState({
            username: data.username,
            password: data.password,
            loaded: true,
          })
        }
      } else {
        console.log('No Data found!');
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  handleSubmit(event) {
    const locationPath = window.location.pathname;
    const param = locationPath.split('/');

    let arrResult = []; let token = [localStorage.getItem('access_token')];
    arrResult = ({ data: this.state, token: token, zoho_acc_id: param[2], update: 'mysql' });

    if (this.state.id !== '' && token !== '') {
      axios.post(global.config.apiHost + 'updateAccountDb', arrResult).then(function (response) {
        if (response.data.status === true) {
          localStorage.setItem('success', 'updated');
          window.location = "/account";
        } else {
          console.log('No getting server response');
        }
      }).catch(function (error) {
        console.log(error);
      })
    } else {
      console.log('error!');
    }
    event.preventDefault();
  }

  render() {

    return (
      <div>
        {/* <Spinner loaded={this.state.loaded} /> */}
        <Header></Header>
        <div className="text-gray-900 bg-gray-200 p-4">

          <div className="grid grid-row-4 grid-flow-col pb-2">
            <div className="row-start-2 pt-2">
              {/* <h1 className="text-3xl">  Create Leads </h1> */}
              <ul class="flex text-gray-500 text-sm lg:text-base">
                <li class="inline-flex items-center">
                  <Link to="/users" className="font-medium text-blue-700">Home</Link>
                  <svg class="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li class="inline-flex items-center">
                  <Link to="/account" className="font-medium text-blue-700">Account</Link>
                  <svg class="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li class="inline-flex items-center"> Update Login</li>
              </ul>
            </div>
            <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to="/account"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Back </button></Link>
            </div>
          </div>

          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
            <form onSubmit={this.handleSubmit}>
              <label className="block tracking-wide text-grey-darker text-base font-medium pb-3" htmlFor="Account Information"> Update Login</label>
              <hr className="p-2"></hr>
              <div className="-mx-3 md:flex mb-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Username">Username  </label>
                  <input name='username' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="username" type="text" placeholder="Enter Username" value={this.state.username}
                    onChange={this.handleChange} />
                  {this.state.message !== '' && <div className="errorMsg text-red-500"> {this.state.message}</div>}
                </div>

                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Password">Password </label>
                  <input name='password' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="password" type="text" placeholder="Enter Password" value={this.state.password}
                    onChange={this.handleChange} />
                </div>
              </div>

              <div className="px-4 py-3 text-center sm:px-6">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Save </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default UpdateLogin