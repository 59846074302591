import React, { Component } from 'react';
import axios from "axios";
import Header from '../../components/layout/Header';
import Spinner from '../../components/Loader';
import '../../config/config'

class User extends Component {
  constructor(props) {
    super(props);
    this.state = { id: '', full_name: '', first_name: '', last_name: '', email: '', dob: '', city: '', state: '', country: '', zip: '', loaded: false, message: 'Loading...', Account_Name: '', username: '', zoho_acc_id: '', Description: '', Account_Type: '', isAdmin: false };

    if (localStorage.getItem('code') == null) {
      window.location = '/';
    }
  }

  componentDidMount() {
    this.getData();
    if (localStorage.getItem('refresh_token') !== '' && localStorage.getItem('code') !== '') {
      this.getUpdatedToken();
    }
  }

  getData = async () => {

    if (localStorage.getItem('userData') !== null) {
      var userData = JSON.parse(localStorage.getItem('userData'));

      this.setState({ id: userData[0].id, full_name: userData[0].full_name, first_name: userData[0].first_name, last_name: userData[0].last_name, email: userData[0].email, dob: userData[0].dob, city: userData[0].city, state: userData[0].state, country: userData[0].country, zip: userData[0].zip, message: '', loaded: true, Account_Name: userData[0].Account_Name, username: userData[0].username, zoho_acc_id: userData[0].zoho_acc_id, Description: userData[0].Description, Account_Type: userData[0].Account_Type, isAdmin: false });

    } else if (localStorage.getItem('adminData') !== null) {
      var adminData = JSON.parse(localStorage.getItem('adminData'));
      this.setState({ id: adminData.users[0].id, full_name: adminData.users[0].full_name, first_name: adminData.users[0].first_name, last_name: adminData.users[0].last_name, email: adminData.users[0].email, dob: adminData.users[0].dob, city: adminData.users[0].city, state: adminData.users[0].state, country: adminData.users[0].country, zip: adminData.users[0].zip, message: '', loaded: true, isAdmin: true });
    } else {
      console.log('Usr data is not exits');
      this.setState({ loaded: true, message: 'User details are not found.' });
    }

    // await axios.get(global.config.apiHost + "getUserData/" + localStorage.getItem('access_token')).then((response) => {
    //   if (response.data.name !== "Error") {
    //     if (response.data.users[0].id !== 0) {
    //       this.setState({ id: response.data.users[0].id, full_name: response.data.users[0].full_name, first_name: response.data.users[0].first_name, last_name: response.data.users[0].last_name, email: response.data.users[0].email, dob: response.data.users[0].dob, city: response.data.users[0].city, state: response.data.users[0].state, country: response.data.users[0].country, zip: response.data.users[0].zip, message: '', loaded: true });
    //     } else {
    //       this.setState({ id: '', full_name: '', first_name: '', last_name: '', email: '', dob: '', city: '', state: '', country: '', zip: '', loaded: true, message: response.data.message });
    //     }
    //   } else {
    //     this.setState({ loaded: true, message: response.data.message });
    //   }
    // }).catch((error) => {
    //   console.log(Object)
    // })
  }

  getUpdatedToken = async () => {
    setInterval(() => {
      axios.get(global.config.apiHost + "updateToken/" + localStorage.getItem('refresh_token')).then((response) => {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('api_domain', response.data.api_domain);
        localStorage.setItem('token_type', response.data.token_type);
      }).catch((error) => {
        console.log(error);
      })
    }, (10000 * 59));
  }

  render() {
    return (
      <div>
        <Header></Header>
        <Spinner loaded={this.state.loaded} />
        <div className="w-full p-6 bg-gray-200">

          {this.state.full_name !== '' && <div className="w-48 mx-auto pt-6 border-b-2 border-orange-500 text-center text-2xl text-blue-800"> {this.state.isAdmin === true ? 'Admin ' : 'Partner'} Profile </div>}

          {this.state.full_name !== '' && <div className="p-2 text-center text-lg text-gray-700">Show profile information getting from zoho.</div>}

          <div className="w-full max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            {this.state.full_name !== '' && <div className="md:flex">
              <div className="md:flex-shrink-0">
                <img className="h-48 w-full object-cover md:h-full md:w-48" src="user_dummy.jpg" alt="Man looking at item at a store" />
                {/* <img className="h-48 w-full object-cover md:h-full md:w-48" src={'https://contacts.zoho.com/file?ID=  &fs=thumb'} alt="Man looking at item at a store" /> */}
              </div>
              <div className="p-8">

                {this.state.username === '' && <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2">  <div className="uppercase tracking-wide text-sm text-blue-800 font-semibold">
                    {this.state.full_name ? this.state.full_name : ''}</div> </div>
                  <div className="text-xs text-pink-700 font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-orange-200 uppercase last:mr-0 mr-1">Admin</div>
                </div>}

                {this.state.username !== '' && <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 ..."> <div className="uppercase tracking-wide text-sm text-blue-800 font-semibold">{this.state.username ? this.state.username + '(' + this.state.zoho_acc_id + ')' : ''}</div></div>
                  <div className="text-xs text-pink-700 font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-600 bg-orange-200 uppercase last:mr-0 mr-1">{this.state.Account_Type}</div>
                </div>}

                <p><a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Email: {this.state.email ? this.state.email : '-'}</a></p>
                {/* <p className="mt-2 text-gray-500"> DOB: {this.state.dob ? this.state.dob : '-'}</p> */}
                <p className="mt-2 text-gray-500"> Address: {this.state.city ? this.state.city : ''} {this.state.state ? this.state.state : ''} {this.state.country ? this.state.country : ''} {this.state.zip ? ', ' + this.state.zip : ''}</p>
                <p className="mt-2 text-gray-500"> Description: {this.state.Description ? this.state.Description : '-'}</p>

              </div>
            </div>}
            {this.state.full_name === '' && <div className="md:flex">
              <div className="p-8">
                <div className="tracking-wide text-sm text-blue-800 font-semibold">
                  {this.state.message}</div>
              </div>
            </div>}
          </div>

          {/* <div class="md:w-2/3 w-full px-3 flex flex-row flex-wrap">
                  <div class="w-full text-right text-gray-700 font-semibold relative pt-3 md:pt-0">
                    <div class="text-2xl text-white leading-tight">Admin User</div>
                    <div class="text-normal text-gray-300 hover:text-gray-400 cursor-pointer"><span class="border-b border-dashed border-gray-500 pb-1">Administrator</span></div>
                    <div class="text-sm text-gray-300 hover:text-gray-400 cursor-pointer md:absolute pt-3 md:pt-0 bottom-0 right-0">Last Seen: <b>2 days ago</b></div>
                  </div>
                </div> */}
        </div>
      </div>
    )
  }
}
export default User;