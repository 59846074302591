import React, { Component } from 'react'
import Header from '../../components/layout/Header'
import axios from "axios";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Spinner from '../../components/Loader';
import { Link } from 'react-router-dom';

class EditQuote extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Deal_Name: '',
      Amount: '',
      Closing_Date: '',
      Account_Name: '',
      Stage: '',
      Type: '',
      Probability: '',
      Next_Step: '',
      Lead_Source: '',
      Campaign_Source: '',
      Contact_Name: '',
      Description: '',
      isButtonDisabled: false,
      message: '',
      loaded: false,
      flag: false,
      StageDD: global.common.StageDD,
      LeadSourceDD: global.common.LeadSourceDD,
      TypeDD: global.common.TypeDD,
      errors: {}, selectOptions: [], isDealNameRequired: true, isClosingDateRequired: true, isAccountNameRequired: true, isStageRequired: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
  } // constructor

  componentDidMount() {
    this.getContact()
    const locationPath = window.location.pathname;
    const param = locationPath.split('/');
    this.setState({ id: param[2] });


    if (this.state.Deal_Name.length !== 0) {
      this.setState({ isDealNameRequired: true });
    } else { this.setState({ isDealNameRequired: false }); }
    if (this.state.Closing_Date.length !== 0) {
      this.setState({ isClosingDateRequired: true });
    } else { this.setState({ isClosingDateRequired: false }); }
    if (this.state.Account_Name.length !== 0) {
      this.setState({ isAccountNameRequired: true });
    } else { this.setState({ isAccountNameRequired: false }); }
    if (this.state.Stage.length !== 0) {
      this.setState({ isStageRequired: true });
    } else { this.setState({ isStageRequired: false }); }

    if (param[2] !== '') {
      this.getData(param[2]);
    } else {
      console.log('Error here !');
    }

  }
  validateForm() {
    let errors = {};
    let formIsValid = true;

    if (this.state.Deal_Name === '') {
      formIsValid = false;
      errors["Deal_Name"] = "Please enter a Deal Name.";
    } else {
      this.setState({ isButtonDisabled: false });
    }

    if (this.state.Closing_Date === '') {
      formIsValid = false;
      errors["Closing_Date"] = "Please select a Closing Date.";
    } else {
      this.setState({ isButtonDisabled: false });
    }
    if (this.state.Account_Name === '') {
      formIsValid = false;
      errors["Account_Name"] = "Please enter a Account_Name.";
    } else {
      this.setState({ isButtonDisabled: false });
    }
    if (this.state.Stage === '') {
      formIsValid = false;
      errors["Stage"] = "Please select a Stage.";
    } else {
      this.setState({ isButtonDisabled: false });
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleChange(event) {
    if (event.target.name === 'Deal_Name') {
      if (event.target.value.length === 0) {
        this.setState({ isDealNameRequired: true, errors: { Deal_Name: 'Please enter a Deal Name.' } });
      } else { this.setState({ isDealNameRequired: false, errors: { Deal_Name: '' } }); }
    }
    if (event.target.name === 'Account_Name') {
      if (event.target.value.length === 0) {
        this.setState({ isAccountNameRequired: true, errors: { Account_Name: 'Please enter a Account Name.' } });
      } else { this.setState({ isAccountNameRequired: false, errors: { Account_Name: '' } }); }
    }

    if (event.target.name === 'Stage') {
      if (event.target.value.length === 0) {
        this.setState({ isStageRequired: true, errors: { Stage: 'Please select a Stage.' } });
      } else { this.setState({ isStageRequired: false, errors: { Stage: '' } }); }
    }

    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleDayChange(Closing_Date) {
    Closing_Date = Closing_Date.toLocaleDateString();
    var monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var newDate = new Date(Closing_Date);
    var formattedDate = newDate.getFullYear() + '-' + monthNames[newDate.getMonth()] + '-' + newDate.getDate();
    if (Closing_Date.length === 0) {
      this.setState({ isClosingDateRequired: true, errors: { Closing_Date: 'Please select a Closing Date.' } });
    } else { this.setState({ isClosingDateRequired: false, errors: { Closing_Date: '' } }); }
    // var res = Closing_Date.replaceAll("/", "-");
    Closing_Date = formattedDate;
    this.setState({
      Closing_Date

    });

  }

  getContact = async () => {
    this.setState({ loaded: false });
    await axios.get(global.config.apiHost + "getContacts/" + localStorage.getItem('access_token')).then((response) => {
      if (response.data.name !== "Error") {
        if (response.data.data.length > 0) {
          let arr = response.data.data;
          const options = arr.map(d => ({
            "id": d.id,
            "name": d.Full_Name
          }))
          options.unshift({ id: null, name: '-None-' });
          this.setState({ selectOptions: options, loaded: true })

        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  handleSubmit(event) {
    this.validateForm();
    this.setState({ isButtonDisabled: true });
    let arr = []; let arr1 = []; let trigger = ["approval", "workflow", "blueprint"]; let token = [localStorage.getItem('access_token')];

    arr.push(this.state);
    arr1 = ({ data: arr, trigger: trigger, token: token });

    if (this.state.id !== '' && token !== '' && this.state.Deal_Name !== '' && this.state.Closing_Date !== '' && this.state.Account_Name !== '' && this.state.Stage !== '') {
      this.setState({ loaded: false });
      axios.post(global.config.apiHost + 'updateDeals', arr1).then(function (response) {
        if (response.data.data[0].code === 'SUCCESS') {
          console.log('update', response.data.data[0]);
          localStorage.setItem('success', 'updated');
          window.location = "/deals";
        } else {
          console.log('No getting server response');
        }
      }).catch(function (error) {
        console.log(error);
      })
    } else {
      console.log('error!');
      this.validateForm();
      this.setState({ isButtonDisabled: false, loaded: true });
    }
    event.preventDefault();
  }

  getData = async (id) => {
    await axios.get(global.config.apiHost + "getDealById/" + id + '/' + localStorage.getItem('access_token')).then((response) => {
      if (response.data.name !== "Error") {
        var Contact_Name = '';
        console.log('response.data.data.length : ', response.data.data.length);
        if (response.data.data[0].Contact_Name !== null) {
          this.setState({ Contact_Name: response.data.data[0].Contact_Name.id });
        }


        if (response.data.data.length !== 0) {
          this.setState({
            Deal_Name: response.data.data[0].Deal_Name,
            Amount: response.data.data[0].Amount,
            Closing_Date: response.data.data[0].Closing_Date,
            Account_Name: response.data.data[0].Account_Name.name,
            Stage: response.data.data[0].Stage,
            Type: response.data.data[0].Type,
            Probability: response.data.data[0].Probability,
            Next_Step: response.data.data[0].Next_Step,
            Lead_Source: response.data.data[0].Lead_Source,
            // Contact_Name: response.data.data[0].Contact_Name.name,
            Campaign_Source: response.data.data[0].Campaign_Source,
            Description: response.data.data[0].Description, loaded: true,
          });

        }

      } else {
        console.log('No Data found!');
        this.setState({ loaded: true, message: response.data.message });
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    const { StageDD, LeadSourceDD, TypeDD, selectOptions } = this.state;
    let leadSourceList = LeadSourceDD.length > 0
      && LeadSourceDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);
    let typeList = TypeDD.length > 0
      && TypeDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);
    let StageList = StageDD.length > 0
      && StageDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);
    let ContactNameList = selectOptions.length > 0
      && selectOptions.map((item, i) => {
        return (
          <option key={i} value={item.id}>{item.name}</option>
        )
      }, this);
    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        <div className="text-gray-900 bg-gray-200 p-4">

          <div className="grid grid-row-4 grid-flow-col pb-2">
            <div className="row-start-2 pt-2">
              {/* <h1 className="text-3xl">  Create Leads </h1> */}
              <ul className="flex text-gray-500 text-sm lg:text-base">
                <li className="inline-flex items-center">
                  <Link to="/users" className="font-medium text-blue-700">Home</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center">
                  <Link to="/deals" className="font-medium text-blue-700">Deals</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center"> Edit Deals </li>
              </ul>
            </div>
            <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to="/deals"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Back </button></Link>
            </div>
          </div>

          {this.state.message !== '' && this.state.flag && <div className="p-4">
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-3" role="alert">
              <p className="font-bold">Error Message !</p>
              <p>{this.state.message}</p>
            </div>
          </div>}

          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
            <form onSubmit={this.handleSubmit}>
              <label className="block tracking-wide text-grey-darker text-base font-medium pb-3" htmlFor="Account Information"> Deals  Information</label>
              <hr className="p-2"></hr>
              <div className="-mx-3 md:flex mb-2">

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Deal_Name"> Deal Name </label>
                  <input name='Deal_Name' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isDealNameRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} id="Deal_Name" type="text" placeholder="Deal Name" value={this.state.Deal_Name}
                    onChange={this.handleChange} />
                  <div className="errorMsg text-red-500"> {this.state.errors.Deal_Name} </div>
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Amount"> Amount</label>
                  <input name='Amount' className=" block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Amount" type="number" placeholder=" Amount" value={this.state.Amount} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Closing_Date"> Closing Date </label>
                  <div>
                    <div className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isClosingDateRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")}>
                      <DayPickerInput placeholder="YYYY-MM-DD" format="YYYY/MM/DD" dayPickerProps={{ modifiers: { disabled: [{ before: new Date() }] } }} value={this.state.Closing_Date} onDayChange={this.handleDayChange} />

                    </div>
                    <div className="errorMsg text-red-500"> {this.state.errors.Closing_Date} </div>
                  </div>

                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Account_Name"> Account Name </label>
                  <input name='Account_Name' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isAccountNameRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} id="Account_Name" type="text" placeholder="Account Name " value={this.state.Account_Name}
                    onChange={this.handleChange} />
                  <div className="errorMsg text-red-500"> {this.state.errors.Account_Name} </div>
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Stage">  Stage </label>
                  <select name='Stage' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isStageRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} id="Stage" value={this.state.Stage} onChange={this.handleChange}>
                    {StageList}
                  </select>
                  <div className="errorMsg text-red-500"> {this.state.errors.Stage} </div>
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Type"> Type </label>
                  <select name='Type' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Type" value={this.state.Type} onChange={this.handleChange}>
                    {typeList}
                  </select>
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Probability"> Probability (%) </label>
                  <input name='Probability' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Probability" type="text" placeholder=" Probability " value={this.state.Probability}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Next_Step"> Next Step </label>
                  <input name='Next_Step' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Next_Step" type="text" placeholder="Next Step  " value={this.state.Next_Step}
                    onChange={this.handleChange} />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Lead_Source">  Lead Source  </label>
                  <select name='Lead_Source' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Lead_Source" value={this.state.Lead_Source} onChange={this.handleChange}>
                    {leadSourceList}
                  </select>
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Campaign_Source"> Campaign Source  </label>
                  <input name='Campaign_Source' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Campaign_Source" type="text" placeholder="Campaign Source  " value={this.state.Campaign_Source}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Contact_Name"> Contact Name </label>
                  <select name='Contact_Name' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Contact_Name" value={this.state.Contact_Name} onChange={this.handleChange}>
                    {ContactNameList}
                  </select>
                </div>
              </div>

              <label className="block tracking-wide text-grey-darker text-base font-medium pt-5 pb-3" htmlFor="Description Information"> Description Information</label>
              <hr className="p-2"></hr>

              <div className="md:flex mb-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Description">Description </label>
                  <textarea name="Description" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Description" type="text" placeholder="Description" value={this.state.Description} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  {/* <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Created_Time">Created Time </label>
                  <input name="Created_Time" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Created_Time" type="text" placeholder="Created Time" value={this.state.Created_Time} onChange={this.handleChange} /> */}
                </div>
              </div>

              <span className="inline-flex justify-center">{this.state.message}</span>

              <div className="px-4 py-3 text-center sm:px-6">
                <button type="submit" className="  inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" disabled={this.state.isButtonDisabled}> Save </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default EditQuote



// <DayPickerInput
//                         placeholder="DD/MM/YYYY"
//                         format="DD/MM/YYYY"
//                         dayPickerProps={{
//                           modifiers: {
//                             disabled: [
//                               {
//                                 before: new Date()
//                               }
//                             ]
//                           }
//                         }}
//                       />