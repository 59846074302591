import React, { Component } from 'react';
import Header from '../../components/layout/Header'
import axios from "axios";
import Toast from '../../components/toast/Toast'
import Spinner from '../../components/Loader';
import { Link } from 'react-router-dom';
import Skeleton from '../../components/skeleton/Skeleton';
import { PencilAltIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/solid';

class Quotes extends Component {
  constructor(props) {
    super(props)
    this.state = { success: false, skeleton: false, flag: false, jsonData: [], loaded: false, message: '', deleteID: '', deleteModel: true, isAdmin: false, page: 1, per_page: 10, more_records: false };
    if (localStorage.getItem('code') == null) {
      window.location = '/';
    }
  } //constructor

  componentDidMount() {
    if (localStorage.getItem('isAdmin') !== null) {
      if (localStorage.getItem('isAdmin') === 'true') {
        this.setState({ isAdmin: true });
      }
    }
    this.getData();
    
  }

  onPaging = async (name) => {
    if (name === 'prev') {
      this.state.page = this.state.page - 1;
    } else { this.state.page = this.state.page + 1; }
    this.setState({ page: this.state.page, per_page: this.state.per_page });
    
    this.getData();
  }

  getData = async () => {
    this.setState({ loaded: true });
    await axios.get(global.config.apiHost + "getQuotes/" + localStorage.getItem('access_token') + '/Quotes/' + this.state.page + '/' + this.state.per_page).then((response) => {
      if (response.data.data.length > 0) {
        let arr = response.data.data;
        this.setState({ jsonData: arr, loaded: true, message: 'No result found.', skeleton: true, more_records: response.data.info.more_records });
      } else {
        this.setState({ jsonData: [], loaded: true, message: response.data.message, skeleton: true });
        global.config.logout();
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        <div className="text-gray-900 bg-gray-200 p-4">
          <div className="grid grid-row-4 grid-flow-col pb-4">
            <div className="row-start-2 ">
              <ul className="flex text-gray-500 text-sm lg:text-base pt-2">
                <li className="inline-flex items-center">
                  <Link to="/users" className="font-medium text-blue-700">Home</Link>
                  <ChevronRightIcon className="h-5 w-auto text-gray-400" />
                </li>
                <li className="inline-flex items-center"> Quotes </li>
              </ul>
            </div>
            {/* {this.state.isAdmin === true && <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to="/add-deals"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Create Deal </button></Link>
            </div>} */}
          </div>
          {this.state.message !== '' && <Toast message={this.state.message} success={this.state.success} />}

          <div className="flex justify-center">
            <table className="w-full text-md bg-white shadow-md rounded mb-4">
              <tbody>
                <tr className="border-b">
                  <th className="text-left p-3 px-5">Subject</th>
                  <th className="text-left p-3 px-5">Quote Stage</th>
                  <th className="text-left p-3 px-5">Grand Total</th>
                  <th className="text-left p-3 px-5">Deal Name</th>
                  <th className="text-left p-3 px-5">Contact Name</th>
                  <th className="text-left p-3 px-5">Account Name</th>
                  <th className="text-left p-3 px-5">Quote Owner</th>
                  {/* {this.state.isAdmin === true && <th className="text-left p-3 px-5 flex justify-center">Action</th>} */}
                </tr>

                {this.state.jsonData.length !== 0 && this.state.jsonData.map((item, index) => (
                  <tr className="border-b hover:bg-blue-100 bg-gray-100 " key={index}>
                    <td className="p-2 px-5">{item.Subject == null ? <span className="text-center">-</span> : item.Subject}</td>
                    <td className="p-2 px-5">{item.Quote_Stage !== null ? item.Quote_Stage : '-'}</td>

                    <td className="p-2 px-5">{item.$currency_symbol} {global.common.numberWithCommas(item.Grand_Total)}
                    </td>
                    <td className="p-2 px-5">{item.Deal_Name.name !== null ? item.Deal_Name.name : '-'}</td>
                    <td className="p-2 px-5">{item.Contact_Name.name !== null ? item.Contact_Name.name : '-'}</td>
                    <td className="p-2 px-5">{item.Account_Name.name !== null ? item.Account_Name.name : '-'}</td>
                    <td className="p-2 px-5">{item.Owner.name !== null ? item.Owner.name : '-'}</td>

                    {/* {this.state.isAdmin === true && <td className="p-2 px-5 flex justify-center">
                      <Link to={'/edit-deals/' + item.id}><button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-blue-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-blue-200"><PencilAltIcon className="h-5 w-5" /></button></Link>
                    </td>} */}
                  </tr>
                ))}

                {this.state.jsonData.length === 0 && this.state.skeleton === false && this.state.isAdmin === true && <Skeleton value={8} />}
                {this.state.jsonData.length === 0 && this.state.skeleton === false && this.state.isAdmin !== true && <Skeleton value={7} />}

                {this.state.jsonData.length === 0 && this.state.skeleton === true && <tr className="text-center">
                  <td colSpan="8" className="p-3 px-5">{this.state.message}</td>
                </tr>}

              </tbody>
            </table>
          </div>

          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                {/* Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                <span className="font-medium">97</span> results */}
                Page Number : {this.state.page}
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button className={'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none' + (this.state.page !== 1 ? ' ' : ' opacity-50 cursor-not-allowed focus:outline-none')} onClick={() => this.onPaging('prev')}>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="">Previous</span>
                </button>
                <button className={'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none' + (this.state.more_records === true ? ' ' : ' opacity-50 cursor-not-allowed focus:outline-none')} onClick={() => this.onPaging('next')}>
                  <span className=""> Next</span><ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>

        </div>

        {
          this.state.deleteModel === false && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <TrashIcon className="h-6 w-6 text-red-600" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title"> Delete Contact </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500"> Are you sure you want to delete contact?</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => this.delete(this.state.deleteID)}> Delete</button>
                  <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => this.setState({ deleteID: '', deleteModel: true })}> Cancel</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div >
    )
  }
}

export default Quotes;