import React, { Component } from 'react'
import Header from '../../components/layout/Header'
import axios from "axios";
import { Link } from 'react-router-dom';
import Spinner from '../../components/Loader';
const common = require('../../config/common');
class AddAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      zoho_acc_id: '',
      Account_Type: 'Partner',
      Account_Name: '',
      Email: '',
      Account_Site: '',
      Account_Number: '',
      Phone: '',
      Website: '',
      Fax: '',
      SIC_Code: '',
      Parent_Account: '',
      Rating: '',
      Billing_City: '',
      Billing_Street: '',
      Billing_State: '',
      Billing_Code: '',
      Billing_Country: '',
      Annual_Revenue: '',
      Shipping_City: '',
      Shipping_State: '',
      Shipping_Street: '',
      Shipping_Code: '',
      Shipping_Country: '',
      Description: '',
      Created_Time: '',
      Ticker_Symbol: '',
      Ownership: '',
      Industry: '',
      isButtonDisabled: false,
      message: '',
      flag: false,
      loaded: true,
      RatingDD: common.RatingDD,
      OwnershipDD: common.OwnershipDD,
      Account_TypeDD: common.Account_TypeDD,
      IndustryDD: common.IndustryDD,
      errors: {},
      isAccountNameRequired: true,
      isEmailRequired: true, clientId: global.config.clientId,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } // constructor

  componentDidMount() {
    //this.getData();
  }

  validateForm() {
    let errors = {};
    let formIsValid = true;

    if (this.state.Account_Name === '') {
      formIsValid = false;
      errors["Account_Name"] = "Please enter a Account Name.";
    } else {
      this.setState({ isButtonDisabled: false });
    }
    if (this.state.Email === '') {
      formIsValid = false;
      errors["Email"] = "Please enter Email.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleSubmit(event) {
    this.validateForm();
    this.setState({ isButtonDisabled: true });
    let arr = []; let arr1 = []; let trigger = ["approval", "workflow", "blueprint"]; let token = [localStorage.getItem('access_token')]; var data = [];
    arr.push(this.state);
    arr1 = ({ data: arr, trigger: trigger, token: token });

    if (this.state.Account_Name !== '' && this.state.Email !== '' && this.state.isEmailRequired === false) {
      var email = this.state.Email;
      this.setState({ loaded: false });
      axios.post(global.config.apiHost + 'createAccount', arr1).then(function (response) {
        if (response.data) {
          localStorage.setItem('success', 'inserted');
          localStorage.setItem('ComeFrom', 'add-account');

          axios.get(global.config.apiHost + "getAccountById/" + response.data.data[0].details.id + '/' + localStorage.getItem('access_token')).then((response) => {
            if (response.data.name !== "Error") {
              if (response.data.data.length > 0) {
                data = response.data.data;
                if (data.length > 0) {
                  Object.assign(data[0], { Email: email });
                  axios.post(global.config.apiHost + 'addAccounts', { arr: data, clientId: global.config.clientId })
                    .then(function (response) {
                      if (response.data.status === true) {
                        window.location = "/account";
                      }
                    }).catch(err => console.log(err.data));
                }
              }
            } else {
              console.log('No Data found!');
            }
          }).catch((error) => {
            console.log(error);
          })
        } else {
          this.setState({ loaded: true });
          console.log('No getting server response');
        }
      }).catch(function (error) {
        console.log(error);
      })
    } else {
      this.validateForm();
      setTimeout(() => { this.setState({ message: '', flag: false, isButtonDisabled: false, loaded: true }); }, 3000);
    }
    event.preventDefault();
  }

  isValidEmailAddress(address) {
    return !!address.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
  }

  handleChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === 'Account_Name') {
      if (event.target.value.length === 0) {
        this.setState({ isAccountNameRequired: true, errors: { Account_Name: 'Please enter a Account Name.' } });
      } else { this.setState({ isAccountNameRequired: false, errors: { Account_Name: '' } }); }
    }
    if (event.target.name === 'Email') {
      var isValid = this.isValidEmailAddress(event.target.value);
      if (event.target.value.length !== 0) {
        if (isValid) {
          this.setState({ isEmailRequired: false, errors: { Email: '' } });
        } else { this.setState({ isEmailRequired: true, errors: { Email: 'Please enter valid Email.' } }); }
      } else { this.setState({ isEmailRequired: true, errors: { Email: 'Please enter Email.' } }); }
    }
  }

  getNewAccountData = async (id) => {
    var data = [];
    await axios.get(global.config.apiHost + "getAccountById/" + id + '/' + localStorage.getItem('access_token')).then((response) => {
      if (response.data.name !== "Error") {
        if (response.data.data.length > 0) {
          data = response.data.data;
          if (data.length > 0) {
            Object.assign(data[0], { Email: this.state.Email });
            axios.post(global.config.apiHost + 'addAccounts', { arr: data, clientId: global.config.clientId })
              .then(res => console.log('data sent.'))
              .catch(err => console.log(err.data));
          }
        }
      } else {
        console.log('No Data found!');
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  render() {
    const { RatingDD, OwnershipDD, Account_TypeDD, IndustryDD } = this.state;
    let RatingList = RatingDD.length > 0
      && RatingDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);

    let IndustryList = IndustryDD.length > 0
      && IndustryDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);

    let OwnershipList = OwnershipDD.length > 0
      && OwnershipDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);

    let Account_TypeList = Account_TypeDD.length > 0 && Account_TypeDD.map((item, i) => {
      return (
        <option key={i} value={item.value}>{item.name}</option>
      )
    }, this);

    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        <div className="text-gray-900 bg-gray-200 p-4">

          <div className="grid grid-row-4 grid-flow-col pb-2">
            <div className="row-start-2 pt-2">
              {/* <h1 className="text-3xl">  Create Leads </h1> */}
              <ul className="flex text-gray-500 text-sm lg:text-base">
                <li className="inline-flex items-center">
                  <Link to="/users" className="font-medium text-blue-700">Home</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center">
                  <Link to="/account" className="font-medium text-blue-700">Account</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center">
                  Create Account
                </li>
              </ul>
            </div>
            <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to="/account"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Back </button></Link>
            </div>
          </div>

          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
            <form onSubmit={this.handleSubmit}>
              <label className="block tracking-wide text-grey-darker text-base font-medium pb-3" htmlFor="Account Information"> Account Information</label>
              <hr className="p-4"></hr>
              <div className="-mx-3 md:flex mb-2">

                {/* <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Username">Username</label>
                  <input name='username' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="username" type="text" placeholder="Enter Username" value={this.state.username}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Password">Password </label>
                  <input name='password' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="password" type="text" placeholder="Enter Password" value={this.state.password}
                    onChange={this.handleChange} />
                </div> */}

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Account_Type"> Account Type </label>
                  <select name='Account_Type' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Account_Type" value={this.state.Account_Type} onChange={this.handleChange}>
                    {Account_TypeList}
                  </select>
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Ownership"> Ownership </label>
                  <select name='Ownership' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Ownership" value={this.state.Ownership} onChange={this.handleChange}>
                    {OwnershipList}
                  </select>
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Industry">Industry </label>
                  <select name='Industry' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Industry" value={this.state.Industry} onChange={this.handleChange}>
                    {IndustryList}
                  </select>
                </div>
              </div>
              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Account_Name">Account Name </label>
                  <input name='Account_Name' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isAccountNameRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} placeholder="Enter Account Name" id="Account_Name" value={this.state.Account_Name} onChange={this.handleChange} />
                  {this.state.errors.Account_Name !== '' && <div className="errorMsg text-red-500"> {this.state.errors.Account_Name} </div>}
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Account_Name">Email</label>
                  <input name='Email' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isEmailRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} placeholder="Enter Email" id="Email" value={this.state.Email} onChange={this.handleChange} />
                  {this.state.errors.Email !== '' && <div className="errorMsg text-red-500"> {this.state.errors.Email} </div>}
                  {this.state.message !== '' && <div className="errorMsg text-red-500"> {this.state.message} </div>}
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Account_Site"> Account Site </label>
                  <input name='Account_Site' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Account_Site" type="text" placeholder="Enter Account Site" value={this.state.Account_Site}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Account_Number"> Account Number </label>
                  <input name='Account_Number' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Account_Number" type="text" placeholder="Enter Account Number" value={this.state.Account_Number}
                    onChange={this.handleChange} />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Ticker_Symbol"> Ticker Symbol </label>
                  <input name='Ticker_Symbol' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Ticker_Symbol" type="text" placeholder="Enter Ticker Symbol" value={this.state.Ticker_Symbol}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Phone">Phone</label>
                  <input name='Phone' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Phone" type="text" placeholder="+91" value={this.state.Phone}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Website"> Website </label>
                  <input name='Website' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Website" type="text" placeholder="Enter Website" value={this.state.Website}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Fax"> Fax </label>
                  <input name='Fax' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Fax" type="text" placeholder="Enter Fax" value={this.state.Fax}
                    onChange={this.handleChange} />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="SIC_Code">SIC Code</label>
                  <input name='SIC_Code' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="SIC_Code" type="text" placeholder="SIC Code" value={this.state.SIC_Code}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Parent_Account"> Parent Account </label>
                  <input name='Parent_Account' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Parent_Account" type="text" placeholder="Parent Account" value={this.state.Parent_Account}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Rating"> Rating </label>
                  <select name='Rating' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Rating" value={this.state.Rating} onChange={this.handleChange}>
                    {RatingList}
                  </select>
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Annual_Revenue"> Annual Revenue </label>
                  <input name='Annual_Revenue' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Annual_Revenue" type="text" placeholder="Annual Revenue " value={this.state.Annual_Revenue}
                    onChange={this.handleChange} />
                </div>
              </div>

              <label className="block tracking-wide text-grey-darker text-base font-medium pt-5 pb-3" htmlFor="Address Information"> Address Information</label>
              <hr className="p-4"></hr>

              <div className="-mx-3 md:flex mb-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Billing_City">Billing City</label>
                  <input name='Billing_City' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Billing_City" type="text" placeholder="Billing City" value={this.state.Billing_City} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Billing_Street"> Billing Street </label>
                  <input name='Billing_Street' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Billing_Street" type="text" placeholder="Enter Billing Street" value={this.state.Billing_Street}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Shipping_City"> Shipping City </label>
                  <input name="Shipping_City" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Shipping_City" type="text" placeholder="Shipping City" value={this.state.Shipping_City} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Shipping_State">Shipping State </label>
                  <input name="Shipping_State" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Shipping_State" type="text" placeholder="Shipping State" value={this.state.Shipping_State} onChange={this.handleChange} />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Billing_State">Billing State</label>
                  <input name='Billing_State' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Billing_State" type="text" placeholder="Billing State " value={this.state.Billing_State}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Billing_Country"> Billing Country </label>
                  <input name='Billing_Country' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Billing_Country" type="text" placeholder="Billing Country " value={this.state.Billing_Country}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Shipping_Street"> Shipping Street </label>
                  <input name='Shipping_Street' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Shipping_Street" type="text" placeholder="Shipping Street " value={this.state.Shipping_Street}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Shipping_Country"> Shipping Country </label>
                  <input name="Shipping_Country" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Shipping_Country" type="text" placeholder="Shipping Country" value={this.state.Shipping_Country} onChange={this.handleChange} />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Billing_Code"> Billing Code </label>
                  <input name='Billing_Code' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Billing_Code" type="text" placeholder="Billing Code" value={this.state.Billing_Code}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3 ">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Shipping_Code"> Shipping Code </label>
                  <input name='Shipping_Code' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Shipping_Code" type="text" placeholder="Shipping Code " value={this.state.Shipping_Code}
                    onChange={this.handleChange} />
                </div>
              </div>

              <label className="block tracking-wide text-grey-darker text-base font-medium pt-5 pb-3" htmlFor="Description Information"> Description Information</label>
              <hr className="p-4"></hr>

              <div className="md:flex mb-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Description">Description </label>
                  <textarea name="Description" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Description" type="text" placeholder="Description" value={this.state.Description} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  {/* <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Created_Time">Created Time </label>
                  <input name="Created_Time" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Created_Time" type="text" placeholder="Created Time" value={this.state.Created_Time} onChange={this.handleChange} /> */}
                </div>
              </div>

              <div className="px-4 py-3 text-center sm:px-6">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" disabled={this.state.isButtonDisabled}> Save </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default AddAccount;