import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from './pages/extra/About';

import Login from './pages/auth/Login';
import CreateUser from './pages/auth/CreateUser'
import ForgotPassword from './pages/auth/ForgotPassword'
import User from './pages/user/User';
import Leads from './pages/leads/Leads';
import AddLeads from './pages/leads/AddLeads';
import EditLeads from './pages/leads/EditLeads';
import ConvertLeads from './pages/leads/ConvertLeads';
import LeadsDetails from './pages/leads/LeadsDetails';

import Contacts from './pages/contacts/Contacts';
import AddContacts from './pages/contacts/AddContacts';
import EditContacts from './pages/contacts/EditContacts';

import Account from './pages/account/Account';
import AddAccount from './pages/account/AddAccount';
import EditAccount from './pages/account/EditAccount';

import Deals from './pages/deals/Deals';
import AddDeals from './pages/deals/AddDeals';
import EditDeals from './pages/deals/EditDeals'

import Quotes from './pages/quotes/Quotes';
import AddQuote from './pages/quotes/EditQuotes';
import EditQuote from './pages/quotes/EditQuotes';

import Product from './pages/product/Product';
import AddProduct from './pages/product/AddProduct';
import EditProduct from './pages/product/EditProduct';
import UpdateLogin from './pages/account/UpdateLogin';
import Success from './pages/leads/ConvertSuccess';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about"><About /></Route>

        <Route path="/leads"><Leads /></Route>
        <Route path="/add-leads"><AddLeads /></Route>
        <Route path="/edit-leads"><EditLeads /></Route>
        <Route path="/edit-leads/:id"><EditLeads /></Route>
        <Route path="/lead-details/:id">< LeadsDetails /></Route>
        <Route path="/convert-leads/:id"><ConvertLeads /></Route>
        <Route path="/convert-success"><Success /></Route>

        <Route path="/update-login/:id"><UpdateLogin /></Route>

        <Route path="/contacts"><Contacts /></Route>
        <Route path="/add-contacts"><AddContacts /></Route>
        <Route path="/edit-contacts/:id"><EditContacts /></Route>

        <Route path="/account"><Account /></Route>
        <Route path="/add-account"><AddAccount /></Route>
        <Route path="/edit-account/:id"><EditAccount /></Route>

        <Route path="/deals"><Deals /></Route>
        <Route path="/add-deals"><AddDeals /></Route>
        <Route path="/edit-deals/:id"><EditDeals /></Route>

        <Route path="/quotes"><Quotes /></Route>
        <Route path="/add-quote"><AddQuote /></Route>
        <Route path="/edit-quote/:id"><EditQuote /></Route>

        <Route path="/product"><Product /></Route>
        <Route path="/add-product"><AddProduct /></Route>
        <Route path="/edit-product"><EditProduct /></Route>

        <Route path="/users"><User /></Route>
        <Route path="/create-user/:zoho_acc_id/:hash_key"><Login /></Route>
        <Route path="/forgot-password"><ForgotPassword /></Route>
        <Route path="/"><Login /></Route>

        {/* <Route path="/login"><Login /></Route> */}
      </Switch>

    </Router>
  );
}

export default App;