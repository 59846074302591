import React, { Component } from 'react'
import Header from '../../components/layout/Header'
import axios from "axios";
import Spinner from '../../components/Loader';
import { Link } from "react-router-dom";
import Skeleton from '../../components/skeleton/Skeleton';
import Toast from '../../components/toast/Toast';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

class Leads extends Component {

  constructor(props) {
    super(props)
    this.state = { success: false, skeleton: false, deleteID: '', flag: false, jsonData: [], loaded: false, message: '', deleteModel: true, self: false, isAdmin: false, page: 1, per_page: 10, more_records: false };
    if (localStorage.getItem('code') === null) {
      window.location = '/';
    }
  } //constructor

  componentDidMount() {

    if (localStorage.getItem('isAdmin') !== null) {
      if (localStorage.getItem('isAdmin') === 'true') {
        this.setState({ isAdmin: true });
      }
    }

    if (localStorage.getItem('success') === 'inserted') {
      this.setState({ message: 'Record Inserted Successfully', success: true });
    }
    if (localStorage.getItem('success') === 'updated') {
      this.setState({ message: 'Record Updated Successfully', success: true });
    }
    if (localStorage.getItem('success') === 'converted') {
      this.setState({ message: 'Lead Converted Successfully', success: true });
    }
    if (localStorage.getItem('error') === 'duplicate data') {
      this.setState({ message: localStorage.getItem('error'), success: true });
    }
    this.getData();
  }


  onPaging = async (name) => {
    if (name === 'prev') {
      this.state.page = this.state.page - 1;
    } else { this.state.page = this.state.page + 1; }
    this.setState({ page: this.state.page, per_page: this.state.per_page });
    this.getData();
  }

  getData = async () => {
    localStorage.removeItem('error');
    this.setState({ page: this.state.page, per_page: this.state.per_page, loaded: true });
    var paging = '';
    if (localStorage.getItem('isAdmin') !== null) {
      if (localStorage.getItem('isAdmin') === 'true') {
        paging = '/' + this.state.page + '/' + this.state.per_page;
      }
    } else {
      paging = '/1/200';
    }
    await axios.get(global.config.apiHost + "getLeads/" + localStorage.getItem('access_token') + '/Leads' + paging).then((response) => {
      if (response.data.name !== "Error") {
        if (response.data.data.length > 0) {
          let arr = response.data.data;
          this.setState({ more_records: response.data.info.more_records })
          var data = []; let id = '';
          if (localStorage.getItem('adminData') !== null) {
            data = JSON.parse(localStorage.getItem('adminData'));
            id = data.users[0].id;
          } else {
            if (localStorage.getItem('userData') !== null) {
              data = JSON.parse(localStorage.getItem('userData'));
              id = data[0].zoho_acc_id;
            }
          }
          axios.get(global.config.apiHost + "getdbLeads/" + id).then((response) => {
            if (response.data.length > 0) {
              let dbLeads = response.data;
              for (var i = 0; i < arr.length; i++) {
                for (var j = 0; j < dbLeads.length; j++) {
                  if (arr[i].id === dbLeads[j].lead_id) {
                    Object.assign(arr[i], { self: true });
                  }
                }
              };
            }
            var arrResult = [];
            if (this.state.isAdmin !== true) {
              arrResult = arr.filter((list) => list.self === true);
              // let isConvert = arr.filter((list) => list.$converted === true);
              // Update is converted true in data base.
              if (localStorage.getItem('comeFrom') !== null) {
                if (localStorage.getItem('comeFrom') === 'addLead') {
                  var userData = '';
                  if (localStorage.getItem('userData') !== null) {
                    userData = JSON.parse(localStorage.getItem('userData'));
                  }
                  for (let i = 0; i < arrResult.length; i++) {
                    axios.post(global.config.apiHost + "updateDbLead", { lead_id: arrResult[i].id, zoho_acc_id: userData[0].zoho_acc_id, lead_json: arrResult[i], clientId: global.config.clientId }).then((response) => {
                    }).catch((error) => {
                      console.log(error);
                    })
                  }
                }
              }
              // Update is converted true in data base.
            } else {
              arrResult = arr;
            }
            if (arrResult.length === 0) {
              this.setState({ more_records: false, jsonData: arrResult, loaded: true, message: 'No result found.', skeleton: true });
            } else {
              this.setState({ jsonData: arrResult, loaded: true, message: '', skeleton: true });
            }
          }).catch((error) => {
            console.log(error);
          })
        }
      } else {
        this.setState({ jsonData: [], loaded: true, message: response.data.message, skeleton: true });
        global.config.logout();
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  deleteLeads = async (id) => {
    this.setState({ loaded: false, deleteModel: true });
    await axios.post(global.config.apiHost + "deleteLead/" + id + '/' + localStorage.getItem('access_token')).then((response) => {
      if (response.data) {
        this.getData();
        this.setState({ skeleton: true, flag: true, loaded: true, message: 'Record Deleted Successfully', success: false });
      } else {
        this.setState({ jsonData: [], loaded: true, message: response.data.message, skeleton: true });
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  render() {
    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        <div className="text-gray-900 bg-gray-200 p-4 ">
          <div className="grid grid-row-4 grid-flow-col pb-4">
            <div className="row-start-2 pt-2 flex">
              {/* <h3 className="text-2xl"> Leads </h3> */}
              <ul className="flex text-gray-500 text-sm lg:text-base">
                <li className="inline-flex items-center">
                  <Link to="/users" className="font-medium text-blue-700">Home</Link>
                  <ChevronRightIcon className="h-5 w-auto text-gray-400" />
                </li>
                <li className="inline-flex items-center"> Leads </li>
              </ul>
              {/* {this.state.isAdmin !== true && <button className="inline-flex items-center justify-center text-green-700 transition-colors duration-150 focus:shadow-outline hover:bg-blue-200 ml-10">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> <span className="ml-2">Indicates own leads</span>
              </button>} */}
            </div>
            <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to="/add-leads"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Create Lead </button></Link>
            </div>
          </div>

          {this.state.message !== '' && <Toast message={this.state.message} success={this.state.success} />}

          <div className="flex justify-center">
            <table className="w-full text-md bg-white shadow-md rounded mb-4">
              <tbody>
                <tr className="border-b" >
                  {/* {this.state.isAdmin !== true && <th></th>} */}
                  {/* <th className="text-left p-3 px-5">id </th> */}

                  <th className="text-left p-3 px-5">Full Name </th>
                  <th className="text-left p-3 px-5">Company</th>
                  <th className="text-left p-3 px-5">Email</th>
                  <th className="text-left p-3 px-5">Address</th>
                  <th className="text-left p-3 px-5">Converted</th>
                  <th className="text-left p-3 px-5">Status</th>
                  <th className="text-left p-3 px-5 flex justify-center">Action</th>
                </tr>

                {this.state.jsonData.length !== 0 && this.state.jsonData.map((item, index) => (

                  <tr className="border-b hover:bg-blue-100 bg-gray-100 " key={index}>

                    {item.$converted === false && <td className="p-2 px-5">{item.Full_Name === null ? <span className="text-center">-</span> : <Link to={'/lead-details/' + item.id} className="text-blue-700">{item.Full_Name}</Link>}</td>}

                    {item.$converted === true && <td className="p-2 px-5">{item.Full_Name === null ? <span className="text-center">-</span> : item.Full_Name}</td>}

                    <td className="p-2 px-5">{item.Company === null ? <span className="text-center">-</span> : item.Company}</td>
                    <td className="p-2 px-5">{item.Email == null ? <span className="text-center">-</span> : item.Email}</td>
                    <td className="p-2 px-5">{(item.City === null || item.City === undefined) ? '' : item.City}
                      {(item.Street === null || item.Street === undefined) ? '' : item.Street}
                      {(item.State === null || item.State === undefined) ? '' : ' ' + item.State}
                      {(item.Zip_Code === null || item.Zip_Code === undefined) ? '' : ' ' + item.Zip_Code}</td>

                    <td className="p-2 px-5">{item.$converted == null ? <span className="text-center">-</span> : item.$converted ? 'Yes' : 'No'}</td>
                    <td className="p-2 px-5 capitalize">
                      <span className={'px-3 inline-flex text-sm leading-6 font-semibold rounded-full' + (item.$approval_state === 'approved' ? ' bg-green-100 text-green-600' : ' bg-red-100 text-red-600')} >{item.$approval_state}</span>
                    </td>

                    <td className="p-2 px-5 flex justify-center">
                      {item.$converted === true && <button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-blue-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-blue-200 opacity-50 cursor-not-allowed"><PencilAltIcon className="h-4 w-4" /></button>}

                      {item.$converted === false && <Link to={'/edit-leads/' + item.id}><button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-blue-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-blue-200"><PencilAltIcon className="h-4 w-4" /></button></Link>}

                      {item.$converted === true && <button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-red-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-red-200 opacity-50 cursor-not-allowed"><TrashIcon className="h-4 w-4" /></button>}

                      {item.$converted === false && <button className="inline-flex items-center justify-center w-8 h-8 mr-2 text-red-700 transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-red-200" onClick={() => this.setState({ deleteID: item.id, deleteModel: false })}><TrashIcon className="h-4 w-4" /></button>}
                    </td>
                  </tr>
                ))}

                {this.state.jsonData.length === 0 && this.state.skeleton === false && <Skeleton value={7} />}
                {this.state.jsonData.length === 0 && this.state.skeleton === true && <tr className="text-center">
                  <td colSpan="6" className="p-2 px-5"> {this.state.message}</td>
                </tr>}
              </tbody>
            </table>
          </div>

          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                {/* Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
                <span className="font-medium">97</span> results */}
                Page Number : {this.state.page}
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button className={'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none' + (this.state.page !== 1 ? ' ' : ' opacity-50 cursor-not-allowed focus:outline-none')} onClick={() => this.onPaging('prev')}>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="">Previous</span>
                </button>
                <button className={'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none' + (this.state.more_records === true ? ' ' : ' opacity-50 cursor-not-allowed focus:outline-none')} onClick={() => this.onPaging('next')}>
                  <span className=""> Next</span><ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>

        {this.state.deleteModel === false && <div className="fixed z-10 inset-0 overflow-y-auto" aria-ledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="text-center p-5 flex-auto justify-center">
                    <TrashIcon className="w-16 h-16 flex items-center text-red-500 mx-auto" />
                    <h2 className="text-xl font-bold py-4 ">Are you sure?</h2>
                    <p className="text-sm text-gray-500 px-8">Do you really want to delete your Lead?  This process cannot be undone</p>
                  </div>
                </div>
              </div>

              <div className="p-3  mt-2 text-center space-x-4 md:block">
                <button className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600" onClick={() => this.deleteLeads(this.state.deleteID)}>Delete</button>
                <button className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100" onClick={() => this.setState({ deleteID: '', deleteModel: true })}> Cancel </button>
              </div>
            </div>
          </div>
        </div>
        }
      </div >
    )
  }
}
export default Leads;