import './Toast.css';
import React, { Component } from 'react'
import eventBus from "../../pages/EventBus";

export class Toast extends Component {
  constructor(props) {
    super(props)
    this.state = { success: this.props.success, color: 'blue', message: this.props.message };
  }

  componentDidMount() {

    if (this.state.success === true) {
      this.setState({ color: 'blue' });
    } else { this.setState({ color: 'red' }); }

    setTimeout(() => {
      this.setState({ message: '', title: '' });
      localStorage.setItem('success', '');
    }, 3000);

    // eventBus.on("couponApply", (data) =>{
    //   console.log('On couponApply' + data.message);
    //   this.setState({ message: data.message });
    // });
  }

  componentWillUnmount() {
    console.log('Remove couponApply');
    eventBus.remove("couponApply");
  }

  render() {
    return (<>
      {this.state.message !== '' && this.state.message !=='No result found.' && <div className="flex flex-col w-1/3 z-1 jusctify-center fixed top-20">
        <div className={'flex items-center bg-' + this.state.color + '-500 border-l-4 border-' + this.state.color + '-700 py-2 px-3 shadow-md mb-2'}>
          <div className={'text-' + this.state.color + '-500 rounded-full bg-white mr-3'}>
              {this.state.success === true ? <svg width="1.8em" height="1.8em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" /> </svg> :
              <svg width="1.8em" height="1.8em" viewBox="0 0 16 16" className="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" /><path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
              </svg>}
          </div>
          <div className="text-white max-w-xs"> {this.state.message} </div>
        </div>
      </div>}
    </>
    )
  }
}

export default Toast


