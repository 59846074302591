import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import axios from "axios";
import Spinner from '../../components/Loader';
import { Link } from 'react-router-dom';

class LeadsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      First_Name: '',
      Last_Name: '',
      Email: '',
      Mobile: '',
      State: '',
      Company: '',
      Owner: '',
      Fax: '',
      Assistant: '',
      Other_Phone: '',
      Home_Phone: '',
      Mailing_Zip: '',
      Website: '',
      Skype_ID: '',
      Designation: '',
      id: '',
      loaded: false,
      OwnerId: '',
      leadId: '',
      leadStatus: '',
      flag: '',
      Amount: '',
      Deal_Name: '',
      Closing_Date: '',
      Lead_Source: '',
      Phone: '',
      Industry: '',
      Annual_Revenue: '',
      Lead_Status: '',
      Rating: '',
      Twitter: '',
      No_of_Employees: '',
      Title: '',
      Secondary_Email: '',
      Street: '',
      Zip_Code: '',
      City: '',
      Country: '',
      Description: '',
      isButtonDisabled: false,
      message: '',
      Lead_StatusDD: global.common.Lead_StatusDD,
      leadSourceDD: global.common.LeadSourceDD,
      RatingDD: global.common.RatingDD,
      IndustryDD: global.common.IndustryDD,
      AssignTo:'', 
      AssignToOption: '',
      isAdmin: false,
      clientId: global.config.clientId
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

    if (localStorage.getItem('isAdmin') !== null) {
      if (localStorage.getItem('isAdmin') === 'true') {
        this.setState({ isAdmin: true });
        this.getAccountList();
      }
    }
    
    const locationPath = window.location.pathname;
    const param = locationPath.split('/');
    this.setState({ id: param[2] });

    if (param[2] !== '') {
      this.getData(param[2]);
    } else {
      console.log('Error here !');
    }
  }

  handleChange(event) {
    console.log('value: ' + event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  getAccountList = async () => {
    await axios.get(global.config.apiHost + "getAccountList/" + localStorage.getItem('access_token')).then((response) => {
      if (response.data.status === true) {       
        if (response.data.data.length > 0) {
          let arr = response.data.data;
          const options = arr.map(d => ({
            "id": d.zoho_acc_id,
            "name": d.Account_Name
          }))
          options.unshift({ id: null, name: '-None-' });
          this.setState({ AssignToOption: options, loaded: true });
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }


  handleSubmit(event) {
    this.setState({ isButtonDisabled: true });
    let arr = []; let arr1 = []; let trigger = ["approval", "workflow", "blueprint"]; let token = [localStorage.getItem('access_token')];

    let data = {
      First_Name: this.state.First_Name,
      Last_Name: this.state.Last_Name,
      Email: this.state.Email,
      Mobile: this.state.Mobile,
      State: this.state.State,
      Company: this.state.Company,
      Fax: this.state.Fax,
      Website: this.state.Website,
      Skype_ID: this.state.Skype_ID,
      Designation: this.state.Designation,
      id: this.state.id,
      AssignTo: this.state.AssignTo,
      clientId: global.config.clientId,
      leadJsonData: this.state
    }
   
    arr.push(data);
    arr1 = ({ data: arr, trigger: trigger, token: token });
    if (this.state.id !== '' && token !== '') {
      var leadId = this.state.id;
      axios.post(global.config.apiHost + 'updateLead', arr1).then(function (response) {
        console.log('response.data.data[0]:' + JSON.stringify(response.data));
        console.log('response.data.data[0]:' + response.data.data[0].code);
        if (response.data.data[0].data !== 'undefined') {
          if (response.data.data[0].code === 'SUCCESS') {
            window.location.href = "/convert-leads/" + leadId;
          }else{ console.log('No getting server response'); }
        } else {
          if(response.data.status === true){
            window.location.href = "/convert-leads/" + leadId;
          }else{
            console.log('No getting server response');
          }
        }
      }).catch(function (error) {
        console.log(error);
      })
    } else {
      console.log('error!');
      this.setState({ isButtonDisabled: false });
    }
    event.preventDefault();
  }

  getData = async (id) => {
    await axios.get(global.config.apiHost + "getLeadsById/" + id + '/' + localStorage.getItem('access_token')).then((response) => {
      if (response.data.name !== "Error") {
        if (response.data.data.length > 0) {
          let leadData = response.data.data[0];
          localStorage.setItem('leadData', JSON.stringify(response.data.data[0]));
          this.setState({
            First_Name: leadData.First_Name, Last_Name: leadData.Last_Name, Email: leadData.Email, Mobile: leadData.Mobile, State: leadData.State, Company: leadData.Company, Owner: leadData.Owner.name, Fax: leadData.Fax,
            Assistant: leadData.Assistant, Other_Phone: leadData.Other_Phone, Home_Phone: leadData.Home_Phone, Mailing_Zip: leadData.Mailing_Zip, Website: leadData.Website, Skype_ID: leadData.Skype_ID, Designation: leadData.Designation, loaded: true, OwnerId: leadData.Owner.id, id: leadData.id, leadStatus: leadData.$converted
          });
        }
      } else {
        console.log('No Data found!');
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {

    let AccountNameList = this.state.AssignToOption.length > 0
    && this.state.AssignToOption.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name}</option>
      )
    }, this);

    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        
        <div className="text-gray-900 bg-gray-200 p-4" >
          <form onSubmit={this.handleSubmit}>

            <div className="grid grid-row-4 grid-flow-col pb-4">
              <div className="row-start-2 pt-2">
                {/* <h3 className="text-2xl"> Leads </h3> */}
                <ul className="flex text-gray-500 text-sm lg:text-base">
                  <li className="inline-flex items-center">
                    <Link to="/users" className="font-medium text-blue-700">Home</Link>
                    <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                    </svg>
                  </li>
                  <li className="inline-flex items-center">
                    <Link to="/leads" className="font-medium text-blue-700">Leads</Link>
                    <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                    </svg>
                  </li>
                  <li className="inline-flex items-center"> Lead Details </li>
                </ul>
              </div>
              <div className="row-start-2 col-end-4 flex flex-row-reverse">
                <Link to="/leads"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Back </button></Link>
              </div>
            </div>

            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="Company"> Company </label>
                  <input name='Company' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Company" type="text" placeholder="Enter Company name" value={this.state.Company}
                    onChange={this.handleChange} readOnly />
                </div>

                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="First_Name"> First Name </label>
                  <input name='First_Name' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="First_Name" type="text" placeholder="Enter first name" value={this.state.First_Name}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="grid-zip"> Last Name </label>
                  <input name='Last_Name' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="First_Name" type="text" placeholder="Enter last name" value={this.state.Last_Name}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="Email"> Email </label>
                  <input name='Email' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Email" type="Email" placeholder="abc@gmail.com" value={this.state.Email}
                    onChange={this.handleChange} />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="State"> State </label>
                  <input name='State' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="State" type="text" placeholder="Enter State name" value={this.state.State}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="mobile">Mobile</label>
                  <input name='Mobile' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="mobile" type="text" placeholder="+91" value={this.state.Mobile}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="Fax">Fax</label>
                  <input name='Fax' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Fax" type="text" placeholder="Fax" value={this.state.Fax}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="grid-city"> Designation </label>
                  <input name='Designation' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="grid-city" type="text" placeholder="Designation" value={this.state.Designation}
                    onChange={this.handleChange} />
                </div>
                {/* <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="grid-city"> Owner </label>
                  <input name='Owner' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="grid-city" type="text" placeholder="Owner" value={this.state.Owner}
                    onChange={this.handleChange} />
                </div> */}
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/4 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="Skype_ID"> Skype ID </label>
                  <input name="Skype_ID" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" placeholder="Skype ID " id="Skype ID" type="text" value={this.state.Skype_ID} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" for="Website"> Website </label>
                  <input name="Website" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Website" type="text" placeholder="https://google.com" value={this.state.Website} onChange={this.handleChange} />
                </div>

                {this.state.isAdmin === true && <div className="md:w-1/4 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="AssignTo"> Assign To </label>
                  <select name='AssignTo' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2  " id="AssignTo" value={this.state.AssignTo} onChange={this.handleChange}> 
                    {AccountNameList}
                  </select>
                </div>}
              </div>

              <div className="px-4 py-3 text-center sm:px-6">
                {/* <Link to={"/convert-leads/" + this.state.leadId} params={this.state}> */}
                <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Convert Lead </button>
                {/* </Link> */}
              </div>
            </div>
          </form>
        </div>
      </div >
    )
  }
}
export default LeadsDetails;