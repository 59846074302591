
module.exports = global.common = {

    numberWithCommas: function numberWithCommas(x) {
        var amount = 0;
        if (x === 'NaN') {
            console.log('NaN');
        } else { amount = parseInt(x); }
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    hashKey : function hashKey(){
        let len = 20, hashKey = "";
        while (hashKey.length < len) hashKey += Math.random().toString(36).substr(2);
        hashKey = hashKey.substr(0, len);
        return hashKey;
    },
    changeDateFormat: function changeDateFormat(closingDate) {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var newDate = new Date(closingDate);
        var formattedDate = monthNames[newDate.getMonth()] + ' ' + newDate.getDate() + ', ' + newDate.getFullYear();
        return formattedDate;
    },
    mobileValidation: function mobileValidation(params) {

    },

    StageDD: [{ id: 0, name: '-None-', value: '' }, { id: 1, name: 'Qualification', value: 'Qualification' }, { id: 2, name: 'Needs Analysis', value: 'Needs Analysis' }, { id: 3, name: 'Value Proposition', value: 'Value Proposition' }, { id: 4, name: 'Identify Decision Makers', value: 'Identify Decision Makers' }, { id: 5, name: 'Proposal/Price Quote', value: 'Proposal/Price Quote' }, { id: 6, name: 'Negotiation/Review', value: 'Negotiation/Review' }, { id: 7, name: 'Closed Won', value: 'Closed Won' }, { id: 8, name: 'Closed Lost', value: 'Closed Lost' }, { id: 9, name: 'Closed-Lost to Competition', value: 'Closed-Lost to Competition' }],

    LeadSourceDD: [{ id: 0, name: '-None-', value: '-None-' }, { id: 1, name: 'Advertisement', value: 'Advertisement' }, { id: 2, name: 'Cold Call', value: 'Cold Call' }, { id: 3, name: 'External Referral', value: 'External Referral' }, { id: 4, name: 'Employee Referral', value: 'Employee Referral' }, { id: 5, name: 'Online Store', value: 'Online Store' }, { id: 6, name: 'Partner', value: 'Partner' }, { id: 7, name: 'Twitter', value: 'Twitter' }, { id: 8, name: 'Public Relations', value: 'Public Relations' }, { id: 9, name: 'Facebook', value: 'Facebook' }, { id: 10, name: 'Sales Email Alias', value: 'Sales Email Alias' }, { id: 11, name: 'Google+', value: 'Google+' }, { id: 12, name: 'Seminar Partner', value: 'Seminar Partner' }, { id: 13, name: 'Internal Seminar', value: 'Internal Seminar' }, { id: 14, name: 'Trade Show', value: 'Trade Show' }, { id: 15, name: 'Web Download', value: 'Web Download' }, { id: 16, name: 'Web Research', value: 'Web Research' }, { id: 17, name: 'Web Cases', value: 'Web Cases' }, { id: 18, name: 'Web Mail', value: 'Web Mail' }, { id: 19, name: 'Chat', value: 'Chat' }],

    TypeDD: [{ id: 0, name: '-None-', value: '-None-' }, { id: 1, name: 'Existing Business', value: 'Existing Business' }, { id: 2, name: 'New Business', value: 'New Business' }],

    ContactRoleDD: [{ id: 0, name: 'None', value: 'None' }, { id: 1, name: 'Developer/Evaluator', value: 'Developer/Evaluator' }, { id: 2, name: 'Decision Maker', value: 'Decision Maker' }, { id: 3, name: 'Purchasing', value: 'Purchasing' }, { id: 4, name: 'Executive Sponsor', value: 'Executive Sponsor' }, { id: 5, name: 'Engineering Lead', value: 'Engineering Lead' }, { id: 6, name: 'Economic Decision Maker', value: 'Economic Decision Maker' }, { id: 7, name: 'Product Management', value: ' Product Management' }],

    LeadStatusDD: [{ id: 0, name: '-None-', value: '-None-' }, { id: 1, name: 'Attempted to Contact', value: 'Attempted to Contact' }, { id: 2, name: 'Contact in Future', value: 'Contact in Future' }, { id: 3, name: 'Contacted', value: 'Contacted' }, { id: 4, name: 'Junk Lead', value: 'Junk Lead' }, { id: 5, name: 'Lost Lead', value: 'Lost Lead' }, { id: 6, name: 'Not Contacted', value: 'Not Contacted' }],

    RatingDD: [{ id: 0, name: '-None-', value: '-None-' }, { id: 1, name: 'Acquired', value: 'Acquired' }, { id: 2, name: 'Active', value: 'Active' }, { id: 3, name: 'Market Failed', value: 'Market Failed' }, { id: 4, name: 'Project Cancelled', value: 'Project Cancelled' }, { id: 5, name: 'Shut Down', value: 'Shut Down' }],

    OwnershipDD: [{ id: 0, name: '-None-', value: '-None-' }, { id: 1, name: 'Other', value: 'Other' }, { id: 2, name: 'Private', value: 'Private' }, { id: 3, name: 'Public', value: 'Public' }, { id: 4, name: 'Subsidiary', value: 'Subsidiary' }, { id: 5, name: 'Government', value: 'Government' }, { id: 6, name: 'Partnership', value: 'Partnership' }, { id: 7, name: 'Privately Held', value: 'Privately Held' }, { id: 8, name: 'Public Company', value: 'Public Company' }],

    Account_TypeDD: [{ id: 0, name: 'Partner', value: 'Partner' }],

    IndustryDD: [{ id: 0, name: '-None-', value: '-None-' }, { id: 1, name: 'ASP (Application Service Provider)', value: 'ASP (Application Service Provider)' }, { id: 2, name: 'Data/Telecom OEM', value: 'Data/Telecom OEM' }, { id: 3, name: 'ERP (Enterprise Resource Planning)', value: 'ERP (Enterprise Resource Planning)' }, { id: 4, name: 'Government/Military', value: 'Government/Military' }, { id: 5, name: 'Large Enterprise', value: 'Large Enterprise' }, { id: 6, name: 'ManagementISV', value: 'ManagementISV' }, { id: 7, name: 'MSP (Management Service Provider)', value: 'MSP (Management Service Provider)' }, { id: 8, name: 'Network Equipment Enterprise', value: 'Network Equipment Enterprise' }, { id: 9, name: 'Non-management ISV', value: 'Non-management ISV' }, { id: 10, name: 'Optical Networking', value: 'Optical Networking' }, { id: 11, name: 'Service Provider', value: 'Service Provider' }, { id: 12, name: 'Small/Medium Enterprise ', value: ' Small/Medium Enterprise' }, { id: 13, name: 'Storage Equipment', value: 'Storage Equipment ' }, { id: 14, name: 'Storage Service Provider', value: 'Storage Service Provider ' }, { id: 15, name: 'Systems Integrator', value: 'Systems Integrator ' }, { id: 16, name: 'Wireless Industry', value: 'Wireless Industry ' }, { id: 17, name: 'Communications', value: 'Communications ' }, { id: 18, name: 'Consulting', value: 'Consulting ' }, { id: 19, name: 'Education', value: 'Education ' }, { id: 20, name: 'Financial Services', value: ' Financial Services' }, { id: 21, name: 'Manufacturing', value: 'Manufacturing' }, { id: 22, name: 'Real Estate', value: 'Real Estate ' }, { id: 23, name: 'Technology', value: ' Technology' }],

    NamePrefixDD: [{ id: 0, name: '-None-', value: '' }, { id: 1, name: 'Mr.', value: 'Mr.' }, { id: 2, name: 'Mrs.', value: 'Mrs.' }, { id: 3, name: 'Ms.', value: 'Ms.' }, { id: 4, name: 'Dr.', value: 'Dr.' }, { id: 5, name: 'Prof.', value: 'Prof.' }],

    AccountTypeNpDD: [{ id: 0, name: '-None-', value: '-None-' }, { id: 1, name: 'Partner', value: 'Partner' }, { id: 2, name: 'Competitor', value: 'Competitor' }, { id: 3, name: 'Customer', value: 'Customer' }, { id: 4, name: 'Distributor', value: 'Distributor' }, { id: 5, name: 'Integrator', value: 'Integrator' }, { id: 6, name: 'Investor', value: 'Investor' }, { id: 7, name: 'Other', value: 'Other' }, { id: 8, name: 'Analyst', value: 'Analyst' }, { id: 9, name: 'Press', value: 'Press' }, { id: 10, name: 'Prospect', value: 'Prospect' }, { id: 11, name: 'Reseller', value: 'Reseller' }, { id: 12, name: ' Supplier', value: 'Supplier ' }, { id: 13, name: 'Vendor', value: 'Vendor ' }],

};