module.exports = global.config = {
    appName: 'Partner Portal',
    apiDomain: "https://www.zohoapis.com",
    authV2Url: "https://accounts.zoho.com/oauth/v2/",
    port: 3001,
    // clientId: "1000.5DV1811T5QZTI4TVS1PGKJLSZL740V",
    // clientSecretId: "edeee84d6f1d20789be35be2c407d2b85dc3035935",
    clientId: "1000.L0NEOCUIKHYMTDZGWXR56HW9L852LW",
    clientSecretId: "77611e702724017f6c48119ed6a5ce17a5c5bc0b70",
    scope: "ZohoCRM.modules.ALL,ZohoCRM.users.ALL",

    authorizationCode: "authorization_code",
    grantTypeRefreshToken: "refresh_token",
    noResultFound: 'No any record found.',

    // hostUrl: "https://blueaspire.thermalcode.com/",
    // redirectUri: "https://blueaspire.thermalcode.com/redirect.html",
    // apiHost: "https://blueaspire.thermalcode.com/",

    // hostUrl: "http://localhost:3001/",
    // apiHost: "http://localhost:4000/",
    // redirectUri: "http://localhost:3001/redirect.html",
    // redirectUri: "https://nms-portal-dot-nms-cp01.nw.r.appspot.com/redirect.html",

    hostUrl: "https://api-portal.thermalcode.com/",
    apiHost: "https://api-portal.thermalcode.com/",
    redirectUri: "https://api-portal.thermalcode.com/redirect.html",

//    hostUrl: 'https://partner-portal-crm.nw.r.appspot.com/',
//    apiHost: 'https://partner-portal-crm.nw.r.appspot.com/',
//    redirectUri: 'https://partner-portal-crm.nw.r.appspot.com/redirect.html',

    logout: function logout() {
        localStorage.clear();
        window.location = '/';
    }
};
