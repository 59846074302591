import React, { Component } from "react";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = { fields: { email: "" },  errors: {}, };
    this.handleChange = this.handleChange.bind(this);
    this.submitUserRegistrationForm = this.submitUserRegistrationForm.bind(this);
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields, });
  }

  submitUserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      // axios
      // .post("", this.state.fields)
      // .then((Response) => {
      //   console.log(Response);
      // })
      // .catch((error) => {
      //   console.log(Object);
      // })
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }
    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  render() {

    return (
      <section className="min-h-screen flex flex-col">
        <div className="flex flex-1 items-center justify-center">
          <div className="rounded-lg sm:border-2 px-4 lg:px-24 py-16 lg:max-w-xl sm:max-w-md w-full text-center">
            <form className="text-center" onSubmit={this.submitUserRegistrationForm}>
              <h1 className="font-bold tracking-wider text-3xl mb-8 w-full text-gray-600">
                Forgot Password
              </h1>

              <div className="py-2 text-left">
                <input
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700"
                  value={this.state.fields.email}
                  onChange={this.handleChange}
                />
                <div className="errorMsg text-red-500">
                  {this.state.errors.email}
                </div>
              </div>

              <div className="py-2">
                <input
                  type="submit"
                  className="border-2 border-gray-100 focus:outline-none bg-blue-800 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-blue-700"
                  value="Get OTP"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default ForgotPassword;
