import React, { Component } from 'react'
import Header from '../../components/layout/Header'
import axios from "axios";
import { Link } from 'react-router-dom';
import Spinner from '../../components/Loader';
import 'react-day-picker/lib/style.css';

class AddContacts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      First_Name: '', Last_Name: '', Email: '', Mobile: '', Owner: '', Lead_Source: '', Date_of_Birth: '', Fax: '', Assistant: '', Other_Phone: '', Home_Phone: '', Mailing_Zip: '', Account_Name: '', Title: '', Department: '', Phone: '', Asst_Phone: '', Skype_ID: '', Secondary_Email: '', Twitter: '', Reporting_To: '', Mailing_Country: '', Mailing_State: '', Mailing_City: '', Mailing_Street: '', Other_City: '', Other_Zip: '', Other_Street: '', Other_State: '', Other_Country: '', Salutation: '', loaded: true,
      Description: '', message: '', isButtonDisabled: false, flag: false,
      NamePrefixDD: global.common.NamePrefixDD,
      LeadSourceDD: global.common.LeadSourceDD,
      errors: {}, isLastNameRequired: true,

      inputArr: [{ name: '' }],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
  } // constructor

  componentDidMount() {
    //this.getData();
  }

  validateForm() {
    let errors = {};
    let formIsValid = true;

    if (this.state.Last_Name === '') {
      formIsValid = false;
      errors["Last_Name"] = "Please enter a Last Name.";
    } else {
      this.setState({ isButtonDisabled: false });
    }
    if (this.state.Mobile.length < 10 || this.state.Mobile.length > 10) {
      let mobile = /^\d{9}$/;
      if (this.state.Mobile !== 10 && !this.state.Mobile.match(mobile)) {
        formIsValid = false;
        errors["Mobile"] = "Please enter a valid Mobile Number.";

      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleDayChange(Date_of_Birth) {
    Date_of_Birth = Date_of_Birth.toLocaleDateString();
    var monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var newDate = new Date(Date_of_Birth);
    var formattedDate = newDate.getFullYear() + '-' + monthNames[newDate.getMonth()] + '-' + newDate.getDate();
    // var res = Date_of_Birth.replaceAll("/", "-");
    // Date_of_Birth = formattedDate;
    this.setState({
      Date_of_Birth: formattedDate
    });
  }

  handleSubmit(event) {
    this.validateForm();
    this.setState({ isButtonDisabled: true });
    let arr = []; let arr1 = []; let trigger = ["approval", "workflow", "blueprint"]; let token = [localStorage.getItem('access_token')]
    arr.push(this.state);
    arr1 = ({ data: arr, trigger: trigger, token: token })
    if (this.state.Last_Name !== '') {
      this.setState({ loaded: false });
      axios.post(global.config.apiHost + 'addContact', arr1).then(function (response) {
        if (response.data) {
          console.log(response.data);
          localStorage.setItem('success', 'inserted');
          window.location = "/contacts";
        } else {
          this.setState({ loaded: true });
          console.log('No getting server response');
        }
      }).catch(function (error) {
        console.log(error);
      })
    } else {
      this.validateForm();
      this.setState({ isButtonDisabled: false, message: '', flag: false, loaded: true });

    }
    event.preventDefault();
  }

  handleChange(event) {
    if (event.target.name === 'Last_Name') {
      if (event.target.value.length === 0) {
        this.setState({ isLastNameRequired: true, errors: { Last_Name: 'Please enter a Last Name.' } });
      } else { this.setState({ isLastNameRequired: false, errors: { Last_Name: '' } }); }
    }
    if (event.target.name === 'Mobile') {
      console.log(event.target.value.length);
      let mobile = /^\d{9}$/;
      if (event.target.value.length > 10 && !event.target.value.match(mobile)) {
        this.setState({ isMobileRequired: true, errors: { Mobile: 'Please enter a valid Mobile Number.' } });

      } else { this.setState({ isMobileRequired: false, errors: { Mobile: '' } }); }
    }
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { LeadSourceDD, NamePrefixDD } = this.state;

    let leadSourceList = LeadSourceDD.length > 0
      && LeadSourceDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);

    let namePrefixList = NamePrefixDD.length > 0
      && NamePrefixDD.map((item, i) => {
        return (
          <option key={i} value={item.value}>{item.name}</option>
        )
      }, this);
    return (
      <div>
        <Spinner loaded={this.state.loaded} />
        <Header></Header>
        <div className="text-gray-900 bg-gray-200 p-4">
          <div className="grid grid-row-4 grid-flow-col pb-2">
            <div className="row-start-2 pt-2">
              <ul className="flex text-gray-500 text-sm lg:text-base">
                <li className="inline-flex items-center">
                  <Link to="/users" className="font-medium text-blue-700">Home</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center">
                  <Link to="/contacts" className="font-medium text-blue-700">Contact</Link>
                  <svg className="h-5 w-auto text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                  </svg>
                </li>
                <li className="inline-flex items-center"> Create Contact </li>
              </ul>
            </div>
            <div className="row-start-2 col-end-4 flex flex-row-reverse">
              <Link to="/contacts"><button className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> Back </button></Link>
            </div>
          </div>

          {this.state.message !== '' && this.state.flag && <div className="p-4">
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-3" role="alert">
              <p className="font-bold">Error Message !</p>
              <p>{this.state.message}</p>
            </div>
          </div>}

          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
            <form onSubmit={this.handleSubmit}>

              <label className="block tracking-wide text-grey-darker text-base font-medium pb-3" htmlFor="Account Information"> Contact Information</label>

              <hr className="p-2"></hr>

              <div className="-mx-3 md:flex mb-2 pt-2">

                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Lead_Source"> Lead Source </label>
                  <select name='Lead_Source' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Lead_Source" value={this.state.Lead_Source} onChange={this.handleChange}>
                    {leadSourceList}
                  </select>
                </div>

                <div className="md:w-1/2 px-3 mb-6 md:mb-0 ">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="First_Name"> First Name </label>
                  <div className="flex">
                    <select name='Salutation' className="block  bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2 rounded-r-none" id="Salutation" value={this.state.Salutation} onChange={this.handleChange}>
                      {namePrefixList} </select>
                    <input name='First_Name' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded rounded-l-none py-2 px-2" id="First_Name" type="text" placeholder="Enter first name" value={this.state.First_Name}
                      onChange={this.handleChange} />
                  </div>
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="grid-zip"> Last Name </label>
                  <input name='Last_Name' className={" block w-full bg-grey-lighter text-grey-darker rounded py-2 px-2  focus:outline-none border " + (this.state.isLastNameRequired === true ? " border-red-600 border-l-4 " : "  border-grey-lighter")} id="First_Name" type="text" placeholder="Enter last name" value={this.state.Last_Name}
                    onChange={this.handleChange} />
                  <div className="errorMsg text-red-500"> {this.state.errors.Last_Name} </div>
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Account_Name"> Account Name </label>
                  <input name='Account_Name' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Account_Name" type="text" placeholder="Account Name" value={this.state.Account_Name}
                    onChange={this.handleChange} />
                </div>

              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Title"> Title </label>
                  <input name='Title' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Title" type="text" placeholder="Title" value={this.state.Title}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Email"> Email </label>
                  <input name='Email' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Email" type="email" placeholder="Enter Email" value={this.state.Email}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Department"> Department </label>
                  <input name='Department' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Department" type="text" placeholder="Department" value={this.state.Department}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Phone">Phone</label>
                  <input name='Phone' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Phone" type="text" placeholder="+91" value={this.state.Phone}
                    onChange={this.handleChange}
                  />
                </div>

              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Home_Phone"> Home Phone </label>
                  <input name='Home_Phone' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Home_Phone" type="text" placeholder="Home Phone" value={this.state.Home_Phone}
                    onChange={this.handleChange} />
                </div>

                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Other_Phone"> Other Phone </label>
                  <input name='Other_Phone' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Industry" type="text" placeholder="Other Phone" value={this.state.Other_Phone}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Fax">Fax</label>
                  <input name='Fax' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Fax" type="text" placeholder="Fax" value={this.state.Fax}
                    onChange={this.handleChange} />
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Mobile"> Mobile </label>
                  <input name='Mobile' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Mobile" type="text" placeholder="Mobile" value={this.state.Mobile}
                    onChange={this.handleChange} />
                  <div className="errorMsg text-red-500"> {this.state.errors.Mobile} </div>
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Assistant"> Assistant </label>
                  <input name="Assistant" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Assistant" placeholder="Assistant" type="text" value={this.state.Assistant} onChange={this.handleChange} />
                </div>

                {/* <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Date_of_Birth"> Date of Birth </label>
                  <div className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2">
                    <DayPickerInput value={this.state.Date_of_Birth} onDayChange={this.handleDayChange} dayPickerProps={{ Date_of_Birth: this.state.Date_of_Birth }} />
                  </div>
                </div> */}

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Asst_Phone"> Asst Phone </label>
                  <input name="Asst_Phone" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Asst_Phone" type="text" placeholder="Asst Phone" value={this.state.Asst_Phone} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Skype_ID"> Skype ID </label>
                  <input name="Skype_ID" className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Skype_ID" type="text" placeholder="Skype ID" value={this.state.Skype_ID} onChange={this.handleChange} />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Secondary_Email"> Secondary Email </label>
                  <input name="Secondary_Email" className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Secondary Email" type="text" placeholder="Secondary Email" value={this.state.Secondary_Email} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Twitter"> Twitter </label>
                  <input name="Twitter" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Twitter" type="text" placeholder="Twitter" value={this.state.Twitter} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Reporting_To"> Reporting To </label>
                  <input name="Reporting_To" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Reporting_To" type="text" placeholder="Reporting To" value={this.state.Reporting_To} onChange={this.handleChange} />
                </div>

              </div>

              <label className="block tracking-wide text-grey-darker text-base font-medium pt-5 pb-3" htmlFor="Address Information"> Address Information</label>
              <hr className="p-2"></hr>

              <div className="-mx-3 md:flex mb-2 pt-2">

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Mailing_Street">  Mailing Street </label>
                  <input name='Mailing_Street' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Mailing_Street" type="text" placeholder="Mailing Street" value={this.state.Mailing_Street}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Other_Street"> Other Street</label>
                  <input name='Other_Street' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Other_Street" type="text" placeholder=" Other Street" value={this.state.Other_Street} onChange={this.handleChange} />
                </div>
              </div>
              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Mailing_City"> Mailing City </label>
                  <input name="Mailing_City" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Mailing_City" type="text" placeholder=" Mailing City" value={this.state.Mailing_City} onChange={this.handleChange} />
                </div>

                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Other_City"> Other City </label>
                  <input name='Other_City' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Other_City" type="text" placeholder="Other City " value={this.state.Other_City}
                    onChange={this.handleChange} />
                </div>

              </div>

              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Mailing_State">  Mailing State </label>
                  <input name='Mailing_State' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Mailing_State" type="text" placeholder=" Mailing State " value={this.state.Mailing_State}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Other_State"> Other State </label>
                  <input name='Other_State' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Other_State" type="text" placeholder="Other State " value={this.state.Other_State}
                    onChange={this.handleChange} />
                </div>
              </div>
              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Mailing_Zip">  Mailing Zip </label>
                  <input name='Mailing_Zip' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Mailing_Zip" type="text" placeholder=" Mailing Zip " value={this.state.Mailing_Zip}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Other_Zip"> Other Zip </label>
                  <input name='Other_Zip' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Other_Zip" type="text" placeholder="Other Zip " value={this.state.Other_Zip}
                    onChange={this.handleChange} />
                </div>
              </div>
              <div className="-mx-3 md:flex mb-2 pt-2">
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Mailing_Country">  Mailing Country </label>
                  <input name='Mailing_Country' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Mailing_Country" type="text" placeholder=" Mailing Country " value={this.state.Mailing_Country}
                    onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Other_Country"> Other Country </label>
                  <input name='Other_Country' className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Other_Country" type="text" placeholder="Other Country " value={this.state.Other_Country}
                    onChange={this.handleChange} />
                </div>
              </div>

              <label className="block tracking-wide text-grey-darker text-base font-medium pt-5 pb-3" htmlFor="Description Information"> Description Information</label>
              <hr className="p-2"></hr>

              <div className="md:flex mb-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Description">Description </label>
                  <textarea name="Description" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Description" type="text" placeholder="Description" value={this.state.Description} onChange={this.handleChange} />
                </div>
                <div className="md:w-1/2 px-3">
                  {/* <label className="block tracking-wide text-grey-darker text-sm font-normal mb-1" htmlFor="Created_Time">Created Time </label>
                  <input name="Created_Time" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-2" id="Created_Time" type="text" placeholder="Created Time" value={this.state.Created_Time} onChange={this.handleChange} /> */}
                </div>
              </div>




              <span className="inline-flex justify-center">{this.state.message}</span>

              <div className="px-4 py-3 text-center sm:px-6">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" disabled={this.state.isButtonDisabled}> Save </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default AddContacts;