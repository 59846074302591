import React, { Component } from 'react';
import Header from '../../components/layout/Header';

class Product extends Component {
    
    render() {
        return (
            <div>
                <Header></Header>
                <div className="text-gray-900 bg-gray-200">
                    <div className="p-4 flex">
                        <h1 className="text-3xl"> Product ( Working... )</h1>
                    </div>
                    <div className="px-3 py-4 flex justify-center">
                        <table className="w-full text-md bg-white shadow-md rounded mb-4">
                            <tbody>
                                <tr className="border-b">
                                    <th className="text-left p-3 px-5">Name</th>
                                    <th className="text-left p-3 px-5">Description</th>
                                    <th className="text-left p-3 px-5">Price</th>
                                    <th className="text-left p-3 px-5">Status</th>
                                    <th className="text-left p-3 px-5 flex justify-center">Action</th>
                                </tr>
                                <tr className="border-b hover:bg-orange-100 bg-gray-100">
                                    <td className="p-3 px-5">Care-Check-in</td>
                                    <td className="p-3 px-5"> New</td>
                                    <td className="p-3 px-5">$120</td>
                                    <td className="p-3 px-5">Active</td>
                                    <td className="p-3 px-5 flex justify-center"><button type="button" className="mr-3 text-sm bg-blue-800 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Edit</button><button type="button" className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Delete</button></td>
                                </tr>
                                <tr className="border-b hover:bg-orange-100 bg-gray-100">
                                    <td className="p-3 px-5">Break Dipslay</td>
                                    <td className="p-3 px-5"> New</td>
                                    <td className="p-3 px-5">$120</td>
                                    <td className="p-3 px-5">Active</td>
                                    <td className="p-3 px-5 flex justify-center"><button type="button" className="mr-3 text-sm bg-blue-800 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Edit</button><button type="button" className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Delete</button></td>
                                </tr>
                                <tr className="border-b hover:bg-orange-100 bg-gray-100">
                                    <td className="p-3 px-5">Break Dipslay</td>
                                    <td className="p-3 px-5"> New</td>
                                    <td className="p-3 px-5">$120</td>
                                    <td className="p-3 px-5">Active</td>
                                    <td className="p-3 px-5 flex justify-center"><button type="button" className="mr-3 text-sm bg-blue-800 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Edit</button><button type="button" className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Delete</button></td>
                                </tr>
                                <tr className="border-b hover:bg-orange-100 bg-gray-100">
                                    <td className="p-3 px-5">Break Dipslay</td>
                                    <td className="p-3 px-5"> New</td>
                                    <td className="p-3 px-5">$120</td>
                                    <td className="p-3 px-5">Active</td>
                                    <td className="p-3 px-5 flex justify-center"><button type="button" className="mr-3 text-sm bg-blue-800 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Edit</button><button type="button" className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Delete</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <Footer></Footer> */}
            </div>
        );
    }
}

export default Product;  