import React, { Component } from 'react'
import axios from "axios";

class Success extends Component {

	constructor(props) {
		super(props)
		this.state = { color: '', title: '', skeleton: false, deleteID: '', flag: false, jsonData: [], loaded: false, message: '', deleteModel: true };
		if (localStorage.getItem('code') === null) {
			window.location = '/';
		}
		this.getData();

	} //constructor

	componentDidMount() {
	}

	getData = async () => {
		// http://localhost:4000/convertLeadRespose/4850796000000839004/4850796000000839008/test-test-test
		await axios.get(global.config.apiHost + "getLeads/" + localStorage.getItem('access_token')).then((response) => {
			console.log(response.data);
		}).catch((error) => {
			console.log(error);
		})
	}

	render() {
		return (
			<div> test</div>
		)
	}
}
export default Success;
